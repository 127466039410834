import * as React from 'react';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { Box, Button, Label, popupController, Popup, PopupProps } from '@redskytech/framework/ui';

export type SiteOfficialPopupProps = PopupProps;

const SiteOfficialPopup: React.FC<SiteOfficialPopupProps> = (props) => {
	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsSiteOfficialPopup'}>
				<PopupHeader
					{...props}
					title={'Contact Site Officials'}
					onClose={() => {
						popupController.close(SiteOfficialPopup);
					}}
				/>
				<Label variant={'body1'} weight={'regular'} margin={'24px 0'}>
					Contact your site officials immediately.
				</Label>
				<Button
					look={'containedPrimary'}
					onClick={() => {
						window.location.reload();
					}}
					fullWidth
				>
					I understand
				</Button>
			</Box>
		</Popup>
	);
};

export default SiteOfficialPopup;
