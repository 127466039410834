import * as React from 'react';
import './WarheadBanner.scss';
import { Icon, Box, Label } from '@redskytech/framework/ui';
import { Link } from '@redskytech/framework/996';
import colors from '../../themes/colors.scss?export';

export enum WarheadBannerStatus {
	PRESENT = 'PRESENT',
	REMOVED = 'REMOVED',
	SAFE = 'SAFE'
}

interface WarheadBannerProps {
	type: WarheadBannerStatus;
	isDeintegrationCheckPage: boolean;
}

const WarHeadBanner: React.FC<WarheadBannerProps> = (props) => {
	function renderBannerContent() {
		switch (props.type) {
			case WarheadBannerStatus.SAFE:
				return (
					<Box className={'allClearBanner warheadBanner'}>
						<Icon iconImg={'icon-shield-check'} color={colors.accentSuccess} />
						<Box className={'allClearText'}>
							<Label variant={'subheader2'} weight={'medium'} color={colors.accentSuccessDark}>
								No warhead present
							</Label>
						</Box>
					</Box>
				);
			case WarheadBannerStatus.PRESENT:
				return (
					<Box className={'dangerBanner warheadBanner'}>
						<Icon iconImg={'icon-shield-exclamation'} color={colors.neutralWhite} />
						<Box className={'dangerText'}>
							<Label variant={'subheader2'} weight={'medium'} color={colors.neutralWhite}>
								Warhead present{' '}
							</Label>
							<Label variant={'subheader2'} weight={'regular'} color={colors.neutralWhite}>
								- Do not proceed with test, contact your site officials.
							</Label>
						</Box>
					</Box>
				);
			case WarheadBannerStatus.REMOVED:
				return (
					<Box className={'warningBanner warheadBanner'}>
						<Icon iconImg={'icon-shield-pending'} color={colors.accentWarning} />
						<Box className={'warningText'}>
							<Label variant={'subheader2'} weight={'medium'} color={colors.accentWarningDark}>
								Warhead is marked as removed by Northrup
							</Label>
							{!props.isDeintegrationCheckPage && (
								<Label variant={'subheader2'} weight={'regular'} color={colors.accentWarningDark}>
									- Please proceed to the{' '}
									<Link path={'/deintegrationcheck'} className={'deintegrationLink'}>
										deintegration check.
									</Link>
								</Label>
							)}
						</Box>
					</Box>
				);
			default:
				return null;
		}
	}

	return <Box className={'rsWarheadBannerContainer'}>{renderBannerContent()}</Box>;
};

export default WarHeadBanner;
