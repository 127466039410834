import { Page } from '@redskytech/framework/996';
import { popupController, rsToastify } from '@redskytech/framework/ui/index.js';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { ApiRequestV1 } from '../../generated/apiRequests.js';
import { fcuPartNumbers, payloadPartNumbers, vehiclePartNumbers } from '../../services/assembly/assembly.data.js';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import AssemblyTypeSelectorSection from '../assemblePage/sections/AssemblyTypeSelectorSection';
import './FlightTestPage.scss';
import FlightTestSection from './sections/FlightTestSection';
import router from '../../utils/router.js';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState.js';
import { TestResult } from '../../services/testFixture/ITestFixtureService.js';
import { ArtifactInfo } from '../../services/calibration/ICalibrationService.js';
import { DataTableFilterMeta, DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup.js';
import debounce from 'lodash.debounce';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FlightTestPageProps {}

const FlightTestPage: React.FC<FlightTestPageProps> = (_props) => {
	const [hwid, setHwid] = useState<HardwareIdDecoded | undefined>();
	const [candidateHwid, setCandidateHwid] = useState<HardwareIdDecoded | undefined>();
	const [candidatePreviousTestId, setCandidatePreviousTestId] = useState<number | undefined>();
	const [_testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [_comments, setComments] = useRecoilState<{ testName: string; comment: string }[]>(globalState.testComments);
	const [popupVisible, setPopupVisible] = useState<boolean>(false);
	const [_testArtifacts, setTestArtifacts] = useRecoilState<ArtifactInfo[]>(globalState.testArtifacts);
	const [testId, setTestId] = useState<string | undefined>();
	const [testRecord, setTestRecord] = useState<Api.V1.TestResult.Get.Res | undefined>();
	const params = router.getQueryParams<{ flightTestId: string; partId: string }>([
		{
			key: 'ti',
			default: '',
			type: 'string',
			alias: 'flightTestId'
		}
	]);

	useEffect(() => {
		if (params.flightTestId) {
			setTestId(params.flightTestId);
			getHwidFromPartId(params.flightTestId);
		}

		async function getHwidFromPartId(flightTestId?: string) {
			if (flightTestId !== undefined) {
				try {
					const testInfo = await ApiRequestV1.getTestResult({ id: Number(flightTestId) });
					if (!testInfo) return;
					setTestRecord(testInfo);
					setTestResults(testInfo.resultData as TestResult[]);

					const commentData: { testName: string; comment: string }[] = [];
					(testInfo.resultData as TestResult[]).forEach((test) => {
						//const comment = comments.find((comment) => comment.testName === test.testName);
						if (test.comment !== undefined) {
							commentData.push({ testName: test.testName, comment: test.comment });
						}
					});

					setComments(commentData);
					if (testInfo.artifacts !== undefined && testInfo.artifacts !== null) {
						const artifactBase = testInfo.artifacts as { data?: ArtifactInfo[] };
						if (artifactBase.data !== undefined) {
							const artifacts = artifactBase.data as ArtifactInfo[];
							setTestArtifacts(artifacts);
						}
					}
					const part = await ApiRequestV1.getPart({ partId: Number(testInfo.partId) });
					if (!part) {
						rsToastify.error('Part not found.', 'Invalid Part Number');
						return;
					}
					setHwid({
						partNumber: part.partNumber,
						serialNumber: part.serialNumber,
						hardwareRevision: part.hardwareRev
					});
				} catch (error) {
					console.log('error', error);
				}
			}
		}
	}, []);

	useEffect(() => {
		// handle candidate hwid being set
		if (!candidateHwid) return;
		if (popupVisible) return;
		if (candidatePreviousTestId !== undefined) {
			setPopupVisible(true);
			popupController.open<ConfirmPopupProps>(ConfirmPopup, {
				title: 'Previous Test Found',
				message:
					'This assembly already has a Flight test on record, would you like to continue it, or start a new test event?',
				confirmButtonText: 'Resume Test',
				closeButtonText: 'New Test',
				onConfirm: () => {
					setPopupVisible(false);
					router.navigate(`/flight/test?ti=${candidatePreviousTestId}`).then(() => {
						window.location.reload();
					});
				},
				onCancel: () => {
					setHwid(candidateHwid);
					setPopupVisible(false);
				},
				onClose: () => {
					setPopupVisible(false);
				}
			});
		} else {
			setHwid(candidateHwid);
		}
	}, [candidateHwid]);

	function renderFlightTestSection() {
		if (!hwid) {
			return (
				<AssemblyTypeSelectorSection
					onAssemblyTypeSelected={debounce(async (assemblyType, hwid) => {
						if (popupVisible) return;
						if (
							!payloadPartNumbers.includes(hwid.partNumber) &&
							!vehiclePartNumbers.includes(hwid.partNumber) &&
							!fcuPartNumbers.includes(hwid.partNumber)
						) {
							rsToastify.error(
								'Part number is not a vehicle, or fcu, or payload type.',
								'Invalid Part Number'
							);
							return;
						}

						// Lets see if the part exists
						try {
							const part = await ApiRequestV1.getPartByNumbers({
								partNumber: hwid.partNumber,
								serialNumber: hwid.serialNumber
							});

							if (!part) {
								rsToastify.error('Part not found.', 'Invalid Part Number');
								return;
							}
							const filters: DataTableFilterMeta = {
								'testResult.testName': {
									operator: 'and',
									constraints: [{ matchMode: 'contains', value: 'Flight' }]
								}
							};
							const filterValues: string[] = [];
							for (const i in filters) {
								if (Object.prototype.hasOwnProperty.call(filters[i], 'constraints')) {
									(filters[i] as DataTableOperatorFilterMetaData).constraints.forEach(
										(constraint: DataTableFilterMetaData) => {
											if (constraint.value !== null)
												filterValues.push(
													`(column:${i},value:${constraint.value},type:${constraint.matchMode})`
												);
										}
									);
								}
							}

							const previousTest = await ApiRequestV1.getTestResultPaged({
								partId: part.id,
								page: 1,
								perPage: 1,
								sortBy: 'testResult.createdOn',
								sortOrder: 'DESC',
								...(filterValues.length && { filter: filterValues.join(',') })
							});

							if (previousTest && previousTest.total >= 1 && previousTest.data[0]) {
								setCandidatePreviousTestId(previousTest.data[0].id);
								setCandidateHwid(hwid);
							} else {
								setCandidateHwid(hwid);
							}
						} catch (error) {
							rsToastify.error('Part not found.', 'Invalid Part Number');
							return;
						}
						// Check and see if our new part has an ongoing flight test record in progress
					}, 500)}
					pageTitle={'Flight Test'}
					labelText={'Scan the hardware identifier of the assembly you want to test.'}
				/>
			);
		}

		return (
			<FlightTestSection
				hwid={hwid}
				testId={testId}
				testRecord={testRecord}
				onDone={() => {
					setHwid(undefined);
				}}
			/>
		);
	}

	return <Page className={'rsFlightTestPage'}>{renderFlightTestSection()}</Page>;
};
export default FlightTestPage;
