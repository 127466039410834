import * as React from 'react';
import { useState, useEffect } from 'react';
import './DeintegrationCheckPage.scss';
import { Page } from '@redskytech/framework/996';
import {
	Box,
	Label,
	RsFormControl,
	rsToastify,
	LabelSelect,
	Button,
	RsFormGroup,
	popupController
} from '@redskytech/framework/ui';
import SelectableInputText from '../../components/selectableInputText/SelectableInputText';
import serviceFactory from '../../services/serviceFactory';
import { ApiRequestV1 } from '../../generated/apiRequests';
import PageHeader from '../../components/pageHeader/PageHeader';
import WarHeadBanner, { WarheadBannerStatus } from '../../components/warheadBanner/WarheadBanner';
import StatusChip from '../../components/statusChip/StatusChip';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { TestStatus } from '../testDetailsPage/TestDetailsPage';
import ResetIntegrationPopup, {
	ResetIntegrationPopupProps
} from '../../popups/resetIntegrationPopup/ResetIntegrationPopup';
import WarheadWarningPopup, { WarheadWarningPopupProps } from '../../popups/warheadWarningPopup/WarheadWarningPopup';

enum FormKeys {
	WARHEAD_STATUS = 'warheadStatus',
	LEEFI_STATUS = 'leefiStatus'
}

enum Status {
	UNKNOWN = 'unknown',
	PRESENT = 'present',
	REMOVED = 'removed'
}

const DeintegrationCheckPage: React.FC = () => {
	const [quickLookupControl, setQuickLookupControl] = useState<RsFormControl<string>>(new RsFormControl('quick', ''));
	const [bannerStatus, setBannerStatus] = useState<WarheadBannerStatus>(WarheadBannerStatus.SAFE);
	const [serialNumber, setSerialNumber] = useState<string>('');
	const [explosivePresent, setExplosivePresent] = useState<boolean>(false);
	const [bitStatus, setBitStatus] = useState<TestStatus>('UNKNOWN');
	const [isConfirmedUser, setIsConfirmedUser] = useState<boolean>(false);
	const [isQuickLookupSelected, setIsQuickLookupSelected] = useState<boolean>(false);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.WARHEAD_STATUS, 'unknown', []),
			new RsFormControl<string>(FormKeys.LEEFI_STATUS, 'unknown', [])
		])
	);

	useEffect(() => {
		if (isConfirmedUser) {
			const warheadStatus = formGroup.get(FormKeys.WARHEAD_STATUS).value;
			const leefiStatus = formGroup.get(FormKeys.LEEFI_STATUS).value;
			if ([warheadStatus, leefiStatus].includes(Status.PRESENT)) {
				setBitStatus('ARMED');
				setExplosivePresent(true);
			} else if ([warheadStatus, leefiStatus].includes(Status.UNKNOWN)) {
				setBitStatus('UNKNOWN');
			} else if (warheadStatus === Status.REMOVED && leefiStatus === Status.REMOVED) {
				setBitStatus('SAFE');
			}
		} else {
			setBitStatus('UNKNOWN');
		}
	}, [isConfirmedUser]);

	async function handleQuickLookUp(value: string, enterPressed: boolean): Promise<'VALID' | string> {
		if (quickLookupControl.value === '') return 'VALID';
		if (!enterPressed) {
			setIsQuickLookupSelected(false);
			return 'VALID';
		}
		setIsQuickLookupSelected(true);

		const assemblyService = serviceFactory.get('AssemblyService');
		const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
		if (!hardwareIdDecoded) {
			rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
			return 'Invalid Hardware ID';
		}

		const part = await ApiRequestV1.getPartByNumbers({
			partNumber: hardwareIdDecoded.partNumber,
			serialNumber: hardwareIdDecoded.serialNumber
		});

		if (part.deintegrationStatus !== null && part.deintegrationStatus !== undefined) {
			setBannerStatus(part.deintegrationStatus as WarheadBannerStatus);
		}

		setSerialNumber(hardwareIdDecoded.serialNumber);

		return 'VALID';
	}

	function handleResetIntegration() {
		popupController.open<ResetIntegrationPopupProps>(ResetIntegrationPopup, {
			onConfirm: () => setIsConfirmedUser(true)
		});
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleCancel() {
		setIsConfirmedUser(false);
	}

	function handleSubmit() {
		if (explosivePresent) {
			popupController.open<WarheadWarningPopupProps>(WarheadWarningPopup);
		} else {
			window.location.reload();
		}
	}

	return (
		<Page className={'rsDeintegrationCheckPage'}>
			<PageHeader
				title={'Deintegration Check'}
				rightNode={
					isConfirmedUser && (
						<Box className={'submitButtonContainer'} display={'flex'} gap={8}>
							<Button look={'outlinedPrimary'} onClick={handleCancel}>
								CANCEL
							</Button>
							<Button look={'containedPrimary'} onClick={handleSubmit}>
								Submit Deintegration
							</Button>
						</Box>
					)
				}
			/>
			{serialNumber && (
				<Box className={'warheadBannerContainer'}>
					<WarHeadBanner type={bannerStatus} isDeintegrationCheckPage={true} />
				</Box>
			)}
			<Box className={'assemblyInputContainer'}>
				<Label variant={'subheader1'} weight={'regular'}>
					Scan hardware identifiers to begin check
				</Label>
				<SelectableInputText
					control={quickLookupControl}
					updateControl={(updatedControl) => setQuickLookupControl(updatedControl)}
					labelTitle={'Assembly'}
					isSelected={isQuickLookupSelected}
					onBlurOrEnter={handleQuickLookUp}
					onClick={() => setIsQuickLookupSelected(true)}
				/>
				{serialNumber && (
					<Box>
						<Label variant={'body1'} weight={'semiBold'}>
							Payloads
						</Label>
						<Box className={'tableHeadings'}>
							<Label variant={'caption1'} weight={'regular'}>
								Serial Number
							</Label>
						</Box>
						<Box className={'deintegrationWrapper'}>
							<Box className={'serialNumberContainer'}>
								<Label variant={'body2'} weight={'semiBold'}>
									{serialNumber}
								</Label>
							</Box>
							<Box className={'dropdownContainer'}>
								<Box className={'dropdownWrapper'} display={'grid'} gap={16}>
									<LabelSelect<{ label: string; value: string }>
										labelTitle={'Warhead is'}
										options={[
											{ label: 'Unknown', value: Status.UNKNOWN },
											{ label: 'Present', value: Status.PRESENT },
											{ label: 'Removed', value: Status.REMOVED }
										]}
										control={formGroup.get(FormKeys.WARHEAD_STATUS)}
										updateControl={handleUpdateControl}
									/>
									<LabelSelect<{ label: string; value: string }>
										labelTitle={'LEEFI is'}
										options={[
											{ label: 'Unknown', value: Status.UNKNOWN },
											{ label: 'Present', value: Status.PRESENT },
											{ label: 'Removed', value: Status.REMOVED }
										]}
										control={formGroup.get(FormKeys.LEEFI_STATUS)}
										updateControl={handleUpdateControl}
									/>
								</Box>
							</Box>
							<Box className={'dropdownButtonContainer'} display={'flex'} gap={16}>
								<Button look={'outlinedPrimary'} onClick={handleResetIntegration}>
									Reset Integration Bit
								</Button>
								<Box display={'flex'} alignItems={'center'} gap={8}>
									<Label variant={'body1'} weight={'regular'}>
										Current Bit Status:{' '}
									</Label>
									<StatusChip status={bitStatus} />
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</Page>
	);
};

export default DeintegrationCheckPage;
