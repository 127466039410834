import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo } from 'react';
import EvaluationItem, {
	EvaluationOptions,
	EvaluationGroup
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface VerifyEsadReadyProps {
	testCriteria: ITestCriteria[];
}

type VerifyEsadEvaluationGroup = EvaluationGroup & {
	verifyEsadWamToReady1: EvaluationOptions | undefined;
	verifyEsadWamToReady2: EvaluationOptions | undefined;
	verifyEsadWamToReady3: EvaluationOptions | undefined;
};

type EvaluationKey = keyof VerifyEsadEvaluationGroup;

const VerifyEsadReady: React.FC<VerifyEsadReadyProps> = (props) => {
	const TEST_NAME: TestKey = 'verifyEsadTransitionToWam';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<VerifyEsadEvaluationGroup>({
		verifyEsadWamToReady1: res ? res.data['verifyEsadWamToReady1'] : undefined,
		verifyEsadWamToReady2: res ? res.data['verifyEsadWamToReady2'] : undefined,
		verifyEsadWamToReady3: res ? res.data['verifyEsadWamToReady3'] : undefined
	});

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			const updatedState = { ...prevState };
			updatedState[key] = evaluation;
			testFixtureService.validateEvaluationTest(TEST_NAME, updatedState, undefined, props.testCriteria);
			return updatedState;
		});
	}

	return (
		<Box className={'rsVerifyEsadReady'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Launch #1: ESAD transition from “WAM” to “Ready”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'verifyEsadWamToReady1');
				}}
				evaluation={evaluations.verifyEsadWamToReady1}
			/>
			<EvaluationItem
				label={'Launch #2: ESAD transition from “WAM” to “Ready”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'verifyEsadWamToReady2');
				}}
				evaluation={evaluations.verifyEsadWamToReady2}
			/>
			<EvaluationItem
				label={'Launch #3: ESAD transition from “WAM” to “Ready”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'verifyEsadWamToReady3');
				}}
				evaluation={evaluations.verifyEsadWamToReady3}
			/>
		</Box>
	);
};
export default VerifyEsadReady;
