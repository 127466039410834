import { Service } from '../Service';
import { io, Socket } from 'socket.io-client';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';
import { boundMethod } from 'autobind-decorator';
import { rsToastify } from '@redskytech/framework/ui/index.js';
import { TestCommand, TestResult } from '../testFixture/ITestFixtureService';
import {
	CalibrationInput,
	CalibrationOutput,
	CalibrationResultOutput,
	FileData,
	FlightFileData
} from '../calibration/ICalibrationService';
import { ObjectUtils, StringUtils } from '../../utils/utils';
import CalibrationService from '../calibration/CalibrationService';
import serviceFactory from '../serviceFactory';
import { FirmwareData } from '../../components/firmwareList/FirmwareList';
import { FlightLogUploadRequest } from '../../pages/flightTestPage/sections/FlightTestSection';

declare global {
	interface BigInt {
		toJSON(): string;
	}
}

BigInt.prototype.toJSON = function () {
	return this.toString();
};

interface SocketMessageError {
	err: string;
}

interface FlightLogResponse {
	success: boolean;
	filePath: string;
}

export interface TesterShimStatusData {
	version: string;
	status: 'CONNECTED_NO_DATA' | 'CONNECTED_DATA' | 'DISCONNECTED';
	packetsPerSecond: number;
}

export interface PortEntryData {
	VTO_PORT: PortEntry;
	VTO_PORT_SN: PortEntry;
	WAKE_PORT: PortEntry;
	WAKE_PORT_SN: PortEntry;
	HOB_PORT: PortEntry;
	HOB_PORT_SN: PortEntry;
	ORIENT_PORT: PortEntry;
	ORIENT_PORT_SN: PortEntry;
}

interface PortEntry {
	desc: string;
	set: string;
}

export default class SocketioService extends Service {
	private shimVideoApiFeatureVersion = '0.5.2';
	private interactiveAbortsFeatureVersion = '0.6.18';
	private interactiveAbortsFeatureAvailable = false;
	private videoApiFeatureAvailable = true;
	private socket!: Socket;
	private calibrationService!: CalibrationService;
	private resultChunk = '';
	private linkState: 'CONNECTED_NO_DATA' | 'CONNECTED_DATA' | 'DISCONNECTED' = 'DISCONNECTED';
	private shimVersion = '';
	private testToolsVersion = '';

	// This returns 1 if the first version is greater, -1 if the second version is greater, and 0 if they are equal
	compareShimVersions(version1: string, version2: string): number {
		const version1Array = version1.split('.').map((item) => parseInt(item));
		const version2Array = version2.split('.').map((item) => parseInt(item));

		for (let i = 0; i < version1Array.length; i++) {
			if (version1Array[i] > version2Array[i]) {
				return 1;
			} else if (version1Array[i] < version2Array[i]) {
				return -1;
			}
		}
		return 0;
	}

	getInteractiveAbortsAvailable(): boolean {
		return this.interactiveAbortsFeatureAvailable;
	}

	getVideoFeatureAvailable(): boolean {
		return this.videoApiFeatureAvailable;
	}

	getShimVersion(): string {
		return this.shimVersion;
	}

	getTestToolsVersion(): string {
		return this.testToolsVersion;
	}

	start() {
		if (!localStorage.getItem('quiet')) {
			this.configureSocket();
		}
		this.calibrationService = serviceFactory.get('CalibrationService');
	}

	syncLinkState(): void {
		setRecoilExternalValue(globalState.testerShimStatus, (prev): TesterShimStatusData => {
			return {
				...prev,
				version: this.shimVersion,
				status: this.linkState
			};
		});
	}

	launchVideoStream(rtspUrl: string): Promise<string> {
		return new Promise((resolve, reject) => {
			this.socket.emit('video:play', rtspUrl, (event: string, webSocketUrlOrError: string) => {
				if (webSocketUrlOrError === 'error') {
					reject();
					return;
				}
				console.log(event, webSocketUrlOrError);
				resolve(webSocketUrlOrError);
			});
		});
	}

	// The fields are all necessary, although firmwareVersion is allowed to be '' when it isn't needed
	runSessionStart(
		scriptName: string,
		portInfo: PortEntryData,
		firmwareVersion: string,
		partTree = '',
		...args: string[]
	) {
		// if the shim version is greater than v0.5.2 we can use this call, else we need to use the old method
		if (this.videoApiFeatureAvailable) {
			this.socket.emit('python:sessionStart', {
				args: [scriptName, ...args],
				portInfo: portInfo,
				firmwareVersion: firmwareVersion,
				partTree: partTree
			});
		} else {
			// Check for -n in the args (which may be present and break the message) and remove it from args
			const argsWithoutN = args.filter((arg) => arg !== '-n');
			// also the old method doesn't support the firmwareVersion field, and the portInfo field is a string, so we need to append those to the arglist
			if (firmwareVersion === '') {
				this.socket.emit('python', [scriptName, ...argsWithoutN, JSON.stringify(portInfo)]);
			} else {
				this.socket.emit('python', [scriptName, ...argsWithoutN, JSON.stringify(portInfo), firmwareVersion]);
			}
		}
	}

	runPythonScript(scriptName: string, ...args: string[]) {
		if (this.videoApiFeatureAvailable) {
			this.socket.emit('python:runScript', { args: [scriptName, ...args] });
		} else {
			this.socket.emit('python', [scriptName, ...args]);
		}
	}

	isResponseAnError(response: FlightLogResponse | SocketMessageError): response is SocketMessageError {
		return (response as SocketMessageError).err !== undefined;
	}

	async runFlightTestScript(file: File) {
		const chunkSize = 1024 * 1024 * 0.5;
		let offset = 0;

		const totalByteLength = file.size;

		this.socket.emit('flightLog:startFile', { totalByteLength });
		while (offset < totalByteLength) {
			const chunk = file.slice(offset, offset + chunkSize);

			this.socket.emit('flightLog:fileChunk', { chunk, offset, totalByteLength });
			offset += chunkSize;
		}

		this.socket.emit('flightLog:fileEnd', file.name);
	}

	sendPromptResponse(data: TestCommand) {
		if (data.command === 'promptButton') {
			setRecoilExternalValue(globalState.testPrompts, (prev) =>
				prev.filter((prompt) => {
					return prompt.id !== data.data.id;
				})
			);
		}

		this.socket.emit('promptResponse', JSON.stringify(data));
	}

	startCalibrationScript() {
		this.socket.emit('connectCalibration');
	}

	sendCalibrationInput(data: CalibrationInput) {
		this.socket.emit('calibration', JSON.stringify(data));
	}

	abortTest(): Promise<string> {
		if (!this.interactiveAbortsFeatureAvailable) {
			this.socket.emit('abort');
			return new Promise((resolve) => {
				resolve('Process abort attempt submitted');
			});
		}
		return new Promise((resolve, reject) => {
			try {
				this.socket.emit('abort', (response: string, success: boolean) => {
					if (!success) {
						console.log('Failed to abort process');
						reject('Error attempting to abort test process.');
					}
					console.log('Succeeded to abort the process');
					resolve(response);
				});
			} catch {
				reject('Error issuing request to abort test process.');
			}
		});
	}

	launchFlightLogTab(filepath: string) {
		this.socket.emit('flightLog:openFileInTab', filepath);
	}

	getFileData(fileData: { partData: FileData['partData']; filePath: string }[]) {
		this.socket.emit('files', fileData);
	}

	getFwSettingsData() {
		this.socket.emit('firmware:getSettings');
	}

	getFwVersionData(): Promise<FirmwareData> {
		return new Promise((resolve) => {
			this.socket.emit('firmware:getVersions', (event: string, data: FirmwareData) => {
				resolve(data);
			});
		});
	}

	uploadFlightLogFile(uploadInfo: FlightLogUploadRequest): Promise<FlightLogResponse | SocketMessageError> {
		return new Promise((resolve) => {
			this.socket.emit(
				'flightLog:uploadArtifact',
				uploadInfo,
				(event: string, data: FlightLogResponse | SocketMessageError) => {
					resolve(data);
				}
			);
		});
	}

	sendFlightLogCleanupRequest() {
		this.socket.emit('flightLog:cleanUp');
	}

	downloadFwVersionData(firmwareVersion: string) {
		this.socket.emit('firmware:download', firmwareVersion);
	}

	deleteFwVersionData(firmwareVersion: string) {
		this.socket.emit('firmware:delete', firmwareVersion);
	}

	activateFwVersionData(firmwareVersion: string) {
		this.socket.emit('firmware:activate', firmwareVersion);
	}

	private configureSocket() {
		this.socket = io('http://localhost:3100');

		this.socket.on('connect', () => {
			this.linkState = 'CONNECTED_NO_DATA';
			setRecoilExternalValue(globalState.testerShimStatus, (prev): TesterShimStatusData => {
				return {
					...prev,
					status: 'CONNECTED_NO_DATA'
				};
			});
		});

		this.socket.on('disconnect', () => {
			this.linkState = 'DISCONNECTED';
			setRecoilExternalValue(globalState.testerShimStatus, (prev): TesterShimStatusData => {
				return {
					...prev,
					status: 'DISCONNECTED'
				};
			});
		});

		this.socket.on('connectCalibration', (data: boolean) => {
			setRecoilExternalValue(globalState.isCalibrationConnected, data);
		});

		this.socket.on('calibrationResult', (data: string) => {
			try {
				const modifiedString = data
					.replace(/\n/g, '')
					.replace(/\s{2,}/gm, '')
					.replace(/}{/gm, '},{');
				let results: any;
				try {
					if (this.resultChunk.length) {
						results = JSON.parse(`{"data": [${this.resultChunk + modifiedString}]}`);
						this.resultChunk = '';
					} else {
						results = JSON.parse(`{"data": [${modifiedString}]}`);
					}
				} catch (e) {
					this.resultChunk += modifiedString;
					return;
				}

				const hasWholeTestError = results.data.some(
					(item: any) =>
						ObjectUtils.isCalibrationResultOutput(item) && !ObjectUtils.isDutData(item) && !item.passed
				);
				setRecoilExternalValue(globalState.slotResultStatuses, (prev) => {
					if (!prev.length) {
						return prev;
					}

					let updatedState = [...prev];

					if (hasWholeTestError) {
						updatedState = updatedState.map((slot) => {
							return { ...slot, status: 'FAILED' };
						});
					} else {
						const statuses: CalibrationResultOutput[] = results.data.filter((output: CalibrationOutput) => {
							return ObjectUtils.isCalibrationResultOutput(output) && ObjectUtils.isDutData(output.data);
						});
						statuses.forEach((status) => {
							const slotStatusIndex = updatedState.findIndex(
								(device) =>
									device.tray === parseInt(status.data.tray.position.toString()) &&
									device.type ===
										StringUtils.convertCarrierType(
											status.data.tray.carrier.type as 'IMU' | 'SIB' | 'BARO'
										) &&
									device.position === parseInt(status.data.DUT.position.toString())
							);
							if (slotStatusIndex !== -1) {
								updatedState[slotStatusIndex] = {
									...updatedState[slotStatusIndex],
									status: status.passed ? 'GOOD' : 'FAILED',
									files: status.data.DUT.files
								};
							}
						});
					}

					return updatedState;
				});

				setRecoilExternalValue(globalState.calibrationOutput, (prev): CalibrationOutput[] => {
					return [
						...results.data.map((result: any) => {
							return {
								...result,
								timeStamp: new Date().toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
									second: '2-digit',
									hour12: true
								})
							};
						}),
						...prev
					];
				});
			} catch (e) {
				console.error(e);
			}
		});

		this.socket.on('files', (files: FileData[]) => {
			this.calibrationService.uploadAndSaveCalibrationResultFiles(files).catch(console.error);
		});

		this.socket.on('flightOutput', (data: FlightFileData) => {
			// This should append a new file to the flight test file data global array
			let flightData = getRecoilExternalValue(globalState.flightTestFileData);
			//copy the array into a bigger size and add the new data
			// if data is an array, then we are getting multiple files at once and should update the array with all of them
			if (Array.isArray(data)) {
				flightData = [...(flightData || []), ...data];
			} else {
				flightData = [...(flightData || []), data];
			}
			setRecoilExternalValue(globalState.flightTestFileData, flightData);
		});

		this.socket.on('result', (data: string, versionInfo?: any) => {
			setRecoilExternalValue(globalState.testPrompts, []);
			setRecoilExternalValue(globalState.rtspVideoUrl, undefined);

			if (!data) return;
			const result = ObjectUtils.safeParse(data);
			if (!('passed' in result) || !('testName' in result)) return;

			if (versionInfo) {
				result.versionInfo = {
					shimVersion: versionInfo.shimVersion,
					testToolsVersion: versionInfo.selectedTestToolsVersion,
					appVersion: import.meta.env.PACKAGE_VERSION
				};
			}

			const formattedResult = ObjectUtils.formatResultObject(result) as TestResult;

			const timeStamp = new Date().toLocaleString([], {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: true
			});
			setRecoilExternalValue(globalState.testResults, (prev): TestResult[] => {
				return [{ ...formattedResult, timeStamp: timeStamp }, ...prev];
			});
		});

		this.socket.on('firmware:downloadFinished', (_data: any) => {
			setRecoilExternalValue(globalState.firmwareDownloadState, 'DONE');
		});
		this.socket.on('firmware:deleteFinished', (_data: any) => {
			setRecoilExternalValue(globalState.firmwareDeleteState, 'DONE');
		});
		this.socket.on('firmware:activateFinished', (_data: any) => {
			setRecoilExternalValue(globalState.firmwareActivateState, 'DONE');
		});
		this.socket.on('firmware:settings', (data: any) => {
			setRecoilExternalValue(globalState.firmwareSettings, {
				commercialFirmware: data.commercialFirmware,
				militaryFirmware: data.militaryFirmware
			});
		});

		this.socket.on('prompt', (data: string) => {
			const prompt = JSON.parse(data);
			if (ObjectUtils.isTestPrompt(prompt)) {
				setRecoilExternalValue(globalState.testPrompts, (prev) => {
					const updatedPrompts = [...prev];
					const promptToOverwriteIndex = updatedPrompts.findIndex(
						(item) => item.position === prompt.position
					);
					if (promptToOverwriteIndex !== -1) {
						// remove prompt in position if no text
						if (!prompt.text) {
							updatedPrompts.splice(promptToOverwriteIndex, 1);
							return updatedPrompts;
						}
						updatedPrompts[promptToOverwriteIndex] = prompt;
					} else {
						// Only show prompts with content.
						if (prompt.text) {
							updatedPrompts.push(prompt);
						}
					}
					return updatedPrompts;
				});
			} else if (ObjectUtils.isTestCommand(prompt)) {
				if (prompt.command === 'displayVideo')
					setRecoilExternalValue(globalState.rtspVideoUrl, {
						...prompt.data,
						xRes: parseInt(prompt.data.xRes),
						yRes: parseInt(prompt.data.yRes)
					});
			}
		});

		// This message only has errors in it.
		this.socket.on('python', (data: any) => {
			let _errorData = data;
			if (ObjectUtils.isObject(data)) {
				_errorData = JSON.stringify(data);
			}
			// Instead of opening the test prompt with all of the details, just redirect to the terminal output.
			rsToastify.error('An error occurred in a processing script. See terminal for details.', 'Script Error');
			setRecoilExternalValue(globalState.testPrompts, []);
			setRecoilExternalValue(globalState.rtspVideoUrl, undefined);
		});

		this.socket.on('python:finish', (data: string) => {
			setRecoilExternalValue(globalState.terminatedTestCode, 2);
			setRecoilExternalValue(globalState.testPrompts, []);
			setRecoilExternalValue(globalState.rtspVideoUrl, undefined);
			rsToastify.warning(data, 'Test Ended');
		});

		this.socket.on('python:output', (data) => {
			rsToastify.warning(data, 'Script Output');
		});

		this.socket.on('python:exit', (data) => {
			setRecoilExternalValue(globalState.terminatedTestCode, 1);
			setRecoilExternalValue(globalState.testPrompts, []);
			setRecoilExternalValue(globalState.rtspVideoUrl, undefined);
			rsToastify.error(data, 'Test Fixture Failure');
		});

		this.socket.on('video:play', (_data: string) => {});

		this.socket.onAny(this.handleMessage);
	}

	@boundMethod
	private handleMessage(event: string, ...args: any[]) {
		if (event === 'version') {
			this.linkState = 'CONNECTED_DATA';
			this.shimVersion = args[0];
			this.testToolsVersion = args[1]?.selectedTestToolsVersion || '0000';
			console.log('Shim version:', this.shimVersion);
			console.log('Test tools version:', this.testToolsVersion);
			this.videoApiFeatureAvailable =
				this.compareShimVersions(this.shimVersion, this.shimVideoApiFeatureVersion) > 0;
			this.interactiveAbortsFeatureAvailable =
				this.compareShimVersions(this.shimVersion, this.interactiveAbortsFeatureVersion) >= 0;
			setRecoilExternalValue(globalState.testerShimStatus, (prev): TesterShimStatusData => {
				return {
					...prev,
					version: args[0],
					status: 'CONNECTED_DATA'
				};
			});
		}
	}
}
