import * as React from 'react';
import './AssemblyNotesPaper.scss';
import { Box, Button, Label, LabelInputTextarea, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import { useState, useEffect } from 'react';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import { WebUtils } from '../../../utils/utils';
import colors from '../../../themes/colors.scss?export';
import { ApiRequestV1 } from '../../../generated/apiRequests';

interface AssemblyNotesPaperProps {
	assemblyDetails: Api.V1.Part.Get.Res;
	partNote?: Api.V1.Note.By.PartId.Get.Res;
}

const NOTES_KEY = 'notes';

const AssemblyNotesPaper: React.FC<AssemblyNotesPaperProps> = (props: AssemblyNotesPaperProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [notes, setNotes] = useState<string>('');
	const [notesFormControl, setNotesFormControl] = useState(
		new RsFormControl<string>(NOTES_KEY, props.partNote?.note || '')
	);

	useEffect(() => {
		setNotesFormControl(new RsFormControl<string>(NOTES_KEY, props.partNote?.note || ''));
		setNotes(props.partNote?.note || '');
	}, [props.partNote]);

	function renderEditNotes(): React.ReactNode {
		if (!isEdit) {
			return (
				<Button look={'outlinedPrimary'} onClick={handleEdit}>
					Edit Notes
				</Button>
			);
		}
	}

	function handleEdit() {
		setIsEdit(true);
	}

	async function onSaveClicked() {
		try {
			await ApiRequestV1.postNote({
				partId: props.assemblyDetails.id,
				note: notesFormControl.value
			});
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleSave() {
		await onSaveClicked();
		setNotes(notesFormControl.value);
		setIsEdit(false);
	}

	function handleCancel() {
		setNotesFormControl(new RsFormControl<string>(NOTES_KEY, notes));
		setIsEdit(false);
	}

	function handleUpdate(control: RsFormControl<string>) {
		setNotesFormControl(control);
	}

	function renderNotes() {
		if (isEdit) {
			return (
				<Box>
					<Box>
						<Label variant={'subheader2'} weight={'regular'} color={colors.neutralGrey700}>
							Notes
						</Label>
						<LabelInputTextarea
							labelTitle={''}
							inputMode={'text'}
							control={notesFormControl}
							updateControl={(control) => handleUpdate(control)}
						/>
					</Box>
					<Box display={'flex'} gap={16} mt={16} justifyContent={'space-between'}>
						<Button look={'outlinedPrimary'} fullWidth={true} onClick={handleCancel}>
							Cancel
						</Button>
						<Button look={'containedPrimary'} fullWidth={true} onClick={handleSave}>
							Save
						</Button>
					</Box>
				</Box>
			);
		} else if (notes) {
			return (
				<Label variant={'body1'} weight={'regular'}>
					{notes}
				</Label>
			);
		} else {
			return (
				<Label
					className={'noNotesLabel'}
					variant={'body1Italic'}
					weight={'regular'}
					color={colors.neutralGrey700}
					textAlign={'center'}
				>
					No notes to display. Add notes by clicking the Edit Notes button.
				</Label>
			);
		}
	}

	return (
		<Box className={'rsAssemblyNotesPaper'}>
			<PaperHeaderBar title={'Assembly Notes'} rightNode={renderEditNotes()}>
				<Box className={'notesContainer'}>{renderNotes()}</Box>
			</PaperHeaderBar>
		</Box>
	);
};

export default AssemblyNotesPaper;
