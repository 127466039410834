import { Page } from '@redskytech/framework/996';
import { Box, Button, Icon, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import { WebUtils } from '@redskytech/framework/utils';
import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import PageHeader from '../../components/pageHeader/PageHeader';
import PartTypeSelector from '../../components/partTypeSelector/PartTypeSelector';
import SerialOnlyPartSelector from '../../components/serialOnlyPartSelector/SerialOnlyPartSelector';
import useWarnOnUnsavedChanges from '../../customHooks/useWarnOnUnsavedChanges';
import { ApiRequestV1 } from '../../generated/apiRequests';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup';
import LoadingPopup, { LoadingPopupProps } from '../../popups/loadingPopup/LoadingPopup';
import { autoCreateableAssemblyPartNumbers } from '../../services/assembly/assembly.data';
import AssemblyService, { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { BasePartType } from '../../services/assembly/IAssemblyService';
import { InspectionGroupType, InspectionResult } from '../../services/kit/IKitService';
import { inspectionGroups } from '../../services/kit/kit.data';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import colors from '../../themes/colors.scss?export';
import router from '../../utils/router';
import './IntersiteChecklistPage.scss';
import IntersiteChecklistGroup from './sections/IntersiteChecklistGroup';
import IntersiteChecklistSection from './sections/IntersiteChecklistSection';
import { DateUtils, StringUtils } from '../../utils/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IntersiteChecklistPageProps {}

interface InspectionLists {
	BATTERY: HardwareIdDecoded[];
	CHARGER: HardwareIdDecoded[];
	VEHICLE: HardwareIdDecoded[];
	PAYLOAD: HardwareIdDecoded[];
	CHASSIS: HardwareIdDecoded[];
	FCU_ANTENNA: HardwareIdDecoded[];
	TABLET: HardwareIdDecoded[];
	AUR: HardwareIdDecoded[];
}

const IntersiteChecklistPage: React.FC<IntersiteChecklistPageProps> = (_props) => {
	const params = router.getQueryParams<{ inspectionNumber: string }>([
		{
			key: 'in',
			default: '',
			type: 'string',
			alias: 'inspectionNumber'
		}
	]);
	const assemblyService = new AssemblyService();
	const [inspectionGuid, setInspectionGuid] = useState<string>(StringUtils.generateGuid());
	const [isModified, setIsModified] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const [keyIndex, setKeyIndex] = useState<number>(0);
	const inspectionResults = useRecoilValue<InspectionResult[]>(globalState.inspectionResults);
	const [location, setLocation] = useState<string>('');
	const [inspectionDate, setInspectionDate] = useState<string>('');
	const [inspector, setInspector] = useState<string>('');
	const [checklistNumber, setChecklistNumber] = useState<string>('');
	const [siteSelected, setSiteSelected] = useState<boolean>(false);
	const [expandAll, setExpandAll] = useState<boolean>(false);
	const [expansionTrigger, setExpansionTrigger] = useState<boolean>(false);
	const [checklistCaption, setChecklistCaption] = useState<string>('');
	const [inspectionLists, setInspectionLists] = useState<InspectionLists>({
		BATTERY: [],
		CHARGER: [],
		VEHICLE: [],
		PAYLOAD: [],
		CHASSIS: [],
		FCU_ANTENNA: [],
		TABLET: [],
		AUR: []
	});
	const siteList = useMemo<{ label: string; value: string }[]>(() => {
		return [
			{ label: 'Oakridge', value: 'OAK' },
			{ label: 'Bozeman', value: 'BZN' },
			{ label: 'Salt Lake', value: 'SLC' }
		];
	}, []);

	useWarnOnUnsavedChanges(isModified);

	useEffect(() => {
		setRecoilExternalValue(globalState.inspectionResults, []);
		if (!params.inspectionNumber) return;
		updateChecklistNumber(params.inspectionNumber);
	}, []);

	useEffect(() => {
		// We have an update to the PO or Kit Number,
		// lets try to download the kit
		const localChecklistNumber = checklistNumber;
		if (localChecklistNumber === '' || localChecklistNumber === null) return;
		(async function getChecklist() {
			const currentChecklist = await ApiRequestV1.getInspection({
				inspectionId: localChecklistNumber.toString()
			});
			// update the list of kitLists with the original inspection data, and also update the global InspectionResults object
			setLocation(currentChecklist.site);
			setInspectionDate(currentChecklist.createdOn);
			setInspector(`${currentChecklist.createdUserFirstName} ${currentChecklist.createdUserLastName}`);
			setSiteSelected(true);
			setIsComplete(currentChecklist.completed);
			setInspectionGuid(currentChecklist.inspectionKey);
			if (currentChecklist.inspectionData) {
				const kitlist: { inspectionResults: InspectionResult[] } = currentChecklist.inspectionData as {
					inspectionResults: InspectionResult[];
				};
				setRecoilExternalValue(globalState.inspectionResults, kitlist.inspectionResults);
			}

			// Add all the preexisting items to the list.
			for (const key in currentChecklist.children) {
				const currentChecklistObj = currentChecklist.children[key];
				if (!inspectionGroups.AUR.partNumbers.includes(currentChecklist.children[key].childPartNumber)) {
					addAssembly(
						{
							partId: currentChecklistObj.childId,
							partNumber: currentChecklistObj.childPartNumber,
							serialNumber: currentChecklistObj.childSerialNumber,
							hardwareRevision: currentChecklistObj.childHardwareRev
						},
						false
					);
					continue;
				}

				const { familyTree } = await ApiRequestV1.getPartFamilyTree({ partId: currentChecklistObj.childId });
				if (!familyTree) continue;

				addAssembly(
					{
						partId: currentChecklistObj.childId,
						partNumber: currentChecklistObj.childPartNumber,
						serialNumber: currentChecklistObj.childSerialNumber,
						hardwareRevision: currentChecklistObj.childHardwareRev,
						children: familyTree.children.map((part) => {
							return {
								serialNumber: part.serialNumber,
								partNumber: part.partNumber,
								partId: part.id,
								hardwareRevision: part.hardwareRev,
								isChild: true
							};
						})
					},
					false
				);
			}
			// altering modified here won't work, as it is set to true in a handler in add assembly, and those ones take effect in a delayed context by one cycle.

			setIsModified(false);
		})();
	}, [checklistNumber]);

	useEffect(() => {
		if (checklistNumber !== '') {
			setChecklistCaption(
				`2. Scan hardware identifiers to add checks for ${
					siteList.find((item) => item.value === location)?.label
				}`
			);
		} else {
			setChecklistCaption(
				`2. Scan hardware identifiers to begin checks for ${
					siteList.find((item) => item.value === location)?.label
				}`
			);
		}
	}, [location, checklistNumber]);

	async function handleSave(complete = false) {
		const loadingPopupId = popupController.open<LoadingPopupProps>(LoadingPopup, {});
		if (isComplete) {
			popupController.closeById(loadingPopupId);
			return;
		}

		try {
			// create an array of assembly data mapped from the arrays of hardware ID's, and setting the name field to be the label on the hardwareID
			const inspectionListsArray = [
				...inspectionLists.BATTERY,
				...inspectionLists.CHARGER,
				...inspectionLists.VEHICLE,
				...inspectionLists.PAYLOAD,
				...inspectionLists.CHASSIS,
				...inspectionLists.FCU_ANTENNA,
				...inspectionLists.TABLET,
				...inspectionLists.AUR
			].map((hwid) => {
				return {
					partNumber: hwid.partNumber,
					serialNumber: hwid.serialNumber,
					hardwareRevision: hwid.hardwareRevision,
					name: assemblyService.getLabelFromPartNumber(hwid.partNumber) || ''
				};
			});

			// get the InspectionResults object and save it into the checklistResults object
			const inspectionUpdates = {
				inspectionResults: { inspectionResults: inspectionResults },
				children: inspectionListsArray,
				site: location,
				inspectionDef: 0,
				completed: complete,
				inspectionKey: inspectionGuid
			};

			setIsComplete(complete);

			const newId = await ApiRequestV1.postInspectionAssemble(inspectionUpdates);
			if (!newId) {
				rsToastify.error('There was an error saving the inspection');
				popupController.closeById(loadingPopupId);
				return;
			}
			setChecklistNumber(newId.toString());
			setIsModified(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
		}
		popupController.closeById(loadingPopupId);
	}

	function renderRightHeaderNode() {
		return (
			<Box display={'flex'} gap={8}>
				<Button
					look={'outlinedPrimary'}
					disabled={!isModified}
					onClick={() => {
						popupController.open<ConfirmPopupProps>(ConfirmPopup, {
							title: 'Cancel Checklist',
							message:
								'Canceling this checklist will lose all progress on this page so far are you sure you want to Cancel?',
							confirmButtonText: 'Cancel',
							closeButtonText: 'Stay',
							onConfirm: () => {
								setIsModified(false);
								setSiteSelected(false);
								setIsComplete(false);
								setLocation('');
								setSiteSelected(false);
								setExpandAll(false);
								setChecklistCaption('');
								setInspectionLists({
									BATTERY: [],
									CHARGER: [],
									VEHICLE: [],
									PAYLOAD: [],
									CHASSIS: [],
									FCU_ANTENNA: [],
									TABLET: [],
									AUR: []
								});
								setKeyIndex((prev) => prev + 1);
							},
							onCancel: () => {}
						});
					}}
				>
					{'Cancel'}
				</Button>
				{!isComplete && (
					<Button
						look={'containedPrimary'}
						onClick={() => {
							handleSave(false);
						}}
					>
						{'Save'}
					</Button>
				)}
				{!isComplete && (
					<Button
						look={'containedPrimary'}
						onClick={() => {
							popupController.open<ConfirmPopupProps>(ConfirmPopup, {
								title: 'Complete Kit',
								message:
									'Completing this checklist will lock it for editing, are you sure you want to complete the checklist?',
								confirmButtonText: 'Complete',
								closeButtonText: 'Cancel',
								onConfirm: () => {
									handleSave(true);
								},
								onCancel: () => {}
							});
						}}
					>
						{'Complete'}
					</Button>
				)}
				{isComplete && (
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.open<ConfirmPopupProps>(ConfirmPopup, {
								title: 'Editing Complete Checklist',
								message:
									'This checklist was completed and may have been shipped, are you sure you want to edit it?',
								confirmButtonText: 'Edit',
								closeButtonText: 'Cancel',
								onConfirm: () => {
									setIsComplete(false);
								},
								onCancel: () => {}
							});
						}}
					>
						{'Edit Checklist'}
					</Button>
				)}
			</Box>
		);
	}

	async function addAssembly(hwid: HardwareIdDecoded | undefined, modifying = true) {
		if (!hwid) {
			rsToastify.error('Please scan a hardware identifier before selecting an assembly type');
			return;
		}

		let part = undefined;
		try {
			part = await ApiRequestV1.getPartByNumbers({
				partNumber: hwid.partNumber,
				serialNumber: hwid.serialNumber
			});
		} catch (e) {
			console.error(e);
		}
		try {
			if (!part) {
				// Is the part one we should auto create?
				if (
					autoCreateableAssemblyPartNumbers.includes(hwid.partNumber) ||
					assemblyService.getBasePartTypeFromPartNumber(hwid.partNumber)
				) {
					// No part was found, but it may be a basepart, or an empty assembly, if that is the case, then this is the time that we will make it
					const assemblyRequest: CustomTypes.AssemblyRequest = {
						partNumber: hwid.partNumber,
						hardwareRevision: hwid.hardwareRevision,
						serialNumber: hwid.serialNumber,
						name: assemblyService.getLabelFromPartNumber(hwid.partNumber) || '',
						assemblyTasksChecked: `""`,
						notes: 'Auto Created during Inspection',
						children: []
					};

					try {
						await ApiRequestV1.postPartAssemble(assemblyRequest);

						part = await ApiRequestV1.getPartByNumbers({
							partNumber: hwid.partNumber,
							serialNumber: hwid.serialNumber
						});
					} catch (e) {
						rsToastify.error(
							WebUtils.getRsErrorMessage(e, 'Error Generating assembly for base part.'),
							'Assembly Error'
						);
						return;
					}
				}
			}

			if (!part) {
				rsToastify.error(
					'This base Part was either entered incorrectly, or could not be created.',
					'Part Not Found'
				);
				return;
			}

			setInspectionLists((prev) => {
				const localInspectionLists = JSON.parse(JSON.stringify(prev));
				let partInList = false;
				// each part type grouping list
				for (const key in localInspectionLists) {
					// check to see if the part number is included in the group
					if (inspectionGroups[key as InspectionGroupType].partNumbers.includes(hwid.partNumber)) {
						// Only push it if it is not already in the list, also don't eror if the part is already in the list.
						if (
							!localInspectionLists[key].some(
								(item: HardwareIdDecoded) =>
									item.partNumber === hwid.partNumber &&
									item.serialNumber === hwid.serialNumber &&
									item.hardwareRevision === hwid.hardwareRevision
							)
						) {
							// We push it into the list
							localInspectionLists[key].push(hwid);
							if (modifying) {
								setIsModified(true);
							}
						}
						// We don't need to push it in again, but the part is definitely in a list.
						partInList = true;
					}
				}
				if (!partInList) {
					rsToastify.error('This hardware identifier does not belong to an inspection group');
				}
				return localInspectionLists;
			});
		} catch (e) {
			rsToastify.error(
				'This part Assembly has either not been prepared, or was entered incorrectly',
				'Assembly not found'
			);
			console.error(e);
		}
	}

	function renderInspectionInfoHeader() {
		return (
			<Box display={'flex'} alignSelf={'flex-start'} flexDirection={'row'} gap={16}>
				<Box alignSelf={'flex-end'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							setExpandAll(false);
							setExpansionTrigger((prev) => {
								return !prev;
							});
						}}
					>
						<Icon iconImg={'icon-chevron-up'} fontSize={14} marginRight={8} /> Collapse All
					</Button>
				</Box>
				<Box alignSelf={'flex-end'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							setExpandAll(true);
							setExpansionTrigger((prev) => {
								return !prev;
							});
						}}
					>
						<Icon iconImg={'icon-chevron-down'} fontSize={14} marginRight={8} /> Expand All
					</Button>
				</Box>
			</Box>
		);
	}

	function renderInspectionGroups() {
		return Object.keys(inspectionLists).map((key) => {
			if (inspectionLists[key as InspectionGroupType].length > 0)
				return (
					<IntersiteChecklistGroup
						removeItem={removeItemFromList}
						key={`${key}${checklistNumber}`}
						group={key as InspectionGroupType}
						idList={inspectionLists[key as InspectionGroupType]}
						allExpanded={expandAll}
						site={location}
						triggerExpanded={expansionTrigger}
						locked={isComplete}
						excludeFlights={location === 'BZN'}
					/>
				);
		});
	}

	function removeItemFromList(group: InspectionGroupType, hwid: HardwareIdDecoded) {
		setInspectionLists((prev) => {
			const localInspectionLists = JSON.parse(JSON.stringify(prev));

			const indexToRemove = localInspectionLists[group].findIndex(
				(item: HardwareIdDecoded) =>
					item.serialNumber === hwid.serialNumber &&
					item.partNumber === hwid.partNumber &&
					item.hardwareRevision === hwid.hardwareRevision
			);

			if (indexToRemove !== -1) {
				localInspectionLists[group].splice(indexToRemove, 1);
			}

			// also look through the InspectionResults and remove the item from there if we deleted an item after checking it
			setRecoilExternalValue(globalState.inspectionResults, (prevState) => {
				const updatedState = [...prevState];
				const removalIndex = updatedState.findIndex((value) => {
					return (
						value.HWID.partNumber === hwid.partNumber &&
						value.HWID.serialNumber === hwid.serialNumber &&
						value.HWID.hardwareRevision === hwid.hardwareRevision
					);
				});
				if (removalIndex > -1) {
					updatedState.splice(removalIndex, 1);
				}
				return updatedState;
			});
			return localInspectionLists;
		});
	}

	function updateChecklistNumber(newNumber: string) {
		setChecklistNumber(newNumber);
		setIsModified(true);
	}

	function updateLocation(site: string) {
		setLocation(site);
		setSiteSelected(true);
		setIsModified(true);
	}

	function renderKitBuildTopSection() {
		// TODO: add logic here to show a button to start a new kit, or continue an old one after entering the number.
		//if (assemblyList.length > 0) { //
		if (siteSelected) {
			return (
				<>
					<PageHeader title={`Intersite Checklist`} rightNode={renderRightHeaderNode()} />
					{checklistNumber === '' && (
						<Box className={'content'} key={`content${keyIndex}`}>
							<IntersiteChecklistSection
								onSiteSelected={updateLocation}
								locationOptionInfo={siteList}
								disabled={true}
							></IntersiteChecklistSection>
						</Box>
					)}
					{checklistNumber !== '' && (
						<Box className={'content'} key={`contentHeader${keyIndex}`}>
							<Label variant={'subheader1'} weight={'regular'} marginTop={16}>
								{'1. Checklist number ' + checklistNumber + ' from ' + location}
							</Label>
							<Label variant={'subheader1'} weight={'regular'} marginTop={16}>
								{'Check last updated on  ' + DateUtils.displayDate(inspectionDate) + ' by ' + inspector}
							</Label>
						</Box>
					)}
					<PartTypeSelector
						onPartTypeSelected={(assemblyType, hwid) => addAssembly(hwid)}
						pageTitle={'Intersite Checklist'}
						clearAfterEnter={true}
						labelText={checklistCaption}
						providePageHeader={false}
					/>{' '}
					<SerialOnlyPartSelector
						onPartTypeSelected={(partType, hwid) => addAssembly(hwid)}
						partType={'TABLET' as BasePartType}
						clearAfterEnter={true}
						labelText={'Enter Tablet Serial Below to add a Tablet.'}
						fullSize={false}
					/>{' '}
					<Box
						className={'content'}
						key={`contentBottom${keyIndex}`}
						display={'flex'}
						flexDirection={'column'}
						gap={16}
					>
						<Box
							display={'flex'}
							flexDirection={'column'}
							borderBottom={checklistNumber === '' ? `1px solid ${colors.neutralGrey500}` : 'none'}
						></Box>
						{/* This section is the fixed Kit Info Header */}
						{renderInspectionInfoHeader()}
						{renderInspectionGroups()}
					</Box>
					<Box className={'bottomSpace'} height={60} gap={16} />
				</>
			);
		} else {
			return (
				<>
					<PageHeader title={'Intersite Checklist'} />
					<Box className={'content'} key={`content${keyIndex}`}>
						<IntersiteChecklistSection
							onSiteSelected={updateLocation}
							locationOptionInfo={siteList}
						></IntersiteChecklistSection>
					</Box>
				</>
			);
		}
	}

	return <Page className={'rsIntersiteChecklistPage'}>{renderKitBuildTopSection()}</Page>;
};
export default IntersiteChecklistPage;
