import * as React from 'react';
import { useState, useEffect } from 'react';
import './DeintegrationPage.scss';
import { Page } from '@redskytech/framework/996';
import {
	Box,
	Label,
	RsFormControl,
	rsToastify,
	Checkbox,
	Icon,
	RsFormGroup,
	Button,
	popupController
} from '@redskytech/framework/ui';
import SelectableInputText from '../../components/selectableInputText/SelectableInputText';
import serviceFactory from '../../services/serviceFactory';
import { ApiRequestV1 } from '../../generated/apiRequests';
import router from '../../utils/router';
import PageHeader from '../../components/pageHeader/PageHeader';
import WarHeadBanner, { WarheadBannerStatus } from '../../components/warheadBanner/WarheadBanner';
import colors from '../../themes/colors.scss?export';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup';

enum FormKeys {
	WARHEAD_REMOVED = 'warheadRemoved',
	LEEFI_REMOVED = 'leefiRemoved',
	TAPE_APPLIED = 'paintersTapeApplied',
	BOXED_SHIPPING = 'boxedForShipping'
}

const DeintegrationPage: React.FC = () => {
	const [quickLookupControl, setQuickLookupControl] = useState<RsFormControl<string>>(new RsFormControl('quick', ''));
	const [isQuickLookupSelected, setIsQuickLookupSelected] = useState<boolean>(false);
	const [bannerStatus, setBannerStatus] = useState<WarheadBannerStatus>(WarheadBannerStatus.SAFE);
	const [serialNumber, setSerialNumber] = useState<string>('');
	const [completedTaskCount, setCompletedTaskCount] = useState<number>(0);
	const [isModified, setIsModified] = useState<boolean>(false);
	const [partId, setPartId] = useState<number>(0);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [checklistFormGroup, setChecklistFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.WARHEAD_REMOVED, false),
			new RsFormControl<boolean>(FormKeys.LEEFI_REMOVED, false),
			new RsFormControl<boolean>(FormKeys.TAPE_APPLIED, false),
			new RsFormControl<boolean>(FormKeys.BOXED_SHIPPING, false)
		])
	);

	useEffect(() => {
		if (!isModified) return;

		function onBeforeUnload(e: BeforeUnloadEvent) {
			e.preventDefault();
			e.returnValue = 'Are you sure you want to leave without submitting? All progress will be lost.';
		}

		window.addEventListener('beforeunload', onBeforeUnload, { capture: true });
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload, { capture: true });
		};
	}, [isModified]);

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate(() => {
			return new Promise((resolve) => {
				if (!isModified || isSubmitting) {
					resolve(false);
					return;
				}

				popupController.open<ConfirmPopupProps>(ConfirmPopup, {
					title: 'Leave Page?',
					message: 'Are you sure you want to leave without submitting? All progress will be lost.',
					confirmButtonText: 'Leave',
					closeButtonText: 'Stay',
					onConfirm: () => {
						resolve(false);
					},
					onCancel: () => {
						resolve(true);
					}
				});
			});
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, [isModified, isSubmitting]);

	async function handleQuickLookUp(value: string, enterPressed: boolean): Promise<'VALID' | string> {
		if (quickLookupControl.value === '') return 'VALID';
		if (!enterPressed) {
			setIsQuickLookupSelected(false);
			return 'VALID';
		}
		setIsQuickLookupSelected(true);

		const assemblyService = serviceFactory.get('AssemblyService');
		const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
		if (!hardwareIdDecoded) {
			rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
			return 'Invalid Hardware ID';
		}

		setSerialNumber(hardwareIdDecoded.serialNumber);
		const part = await ApiRequestV1.getPartByNumbers({
			partNumber: hardwareIdDecoded.partNumber,
			serialNumber: hardwareIdDecoded.serialNumber
		});
		setPartId(part.id);
		if (part.deintegrationStatus !== null && part.deintegrationStatus !== undefined) {
			setBannerStatus(part.deintegrationStatus as WarheadBannerStatus);
		}

		return 'VALID';
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setChecklistFormGroup(checklistFormGroup.clone().update(control));
		const completedCount = checklistFormGroup.getControls().filter((control) => control.value === true).length;
		if (completedCount > 0) setIsModified(true);
		setCompletedTaskCount(completedCount);
	}

	async function handleSubmit() {
		if (completedTaskCount === 4) {
			setIsSubmitting(true);
			await ApiRequestV1.patchPartUpdateDeintegrationStatus({
				id: partId,
				deintegrationStatus: 'REMOVED'
			});
			router.navigate(`/assembly/details?pi=${partId}`).catch(console.error);
		} else {
			rsToastify.error('You have not completed all of the checks.', 'Error');
			setIsSubmitting(false);
		}
	}

	function handleCancel() {
		setChecklistFormGroup(checklistFormGroup.clone().resetToInitialValue());
		setIsModified(false);
	}

	function renderDeintegrationVerification() {
		return (
			<Box>
				<Label variant={'body1'} weight={'semiBold'}>
					Payload
				</Label>
				<Box className={'tableHeadings'}>
					<Label variant={'caption1'} weight={'regular'}>
						Serial Number
					</Label>
					<Label variant={'caption1'} weight={'regular'}>
						Checks
					</Label>
				</Box>
				<Box className={'deintegrationWrapper'}>
					<Box className={'serialNumberContainer'}>
						<Label variant={'body2'} weight={'semiBold'}>
							{serialNumber}
						</Label>
						<Box className={'pendingTasksNumberContainer'}>
							<Icon
								iconImg={completedTaskCount === 4 ? 'icon-check' : 'icon-pending'}
								color={colors.neutralGrey600}
							></Icon>
							<Label variant={'body2'} weight={'semiBold'} color={colors.neutralGrey700}>
								{completedTaskCount} / 4
							</Label>
						</Box>
					</Box>
					<Box className={'checkboxWrapper'}>
						<Checkbox
							look={'containedPrimary'}
							labelText={'Warhead has been removed'}
							control={checklistFormGroup.get(FormKeys.WARHEAD_REMOVED)}
							updateControl={handleUpdateControl}
						/>
						<Checkbox
							look={'containedPrimary'}
							labelText={'LEEFI has been removed'}
							control={checklistFormGroup.get(FormKeys.LEEFI_REMOVED)}
							updateControl={handleUpdateControl}
						/>
						<Checkbox
							look={'containedPrimary'}
							labelText={'Blue painters tape applied over -200 label'}
							control={checklistFormGroup.get(FormKeys.TAPE_APPLIED)}
							updateControl={handleUpdateControl}
						/>
						<Checkbox
							look={'containedPrimary'}
							labelText={'Payload is deintegrated and boxed for shipping'}
							control={checklistFormGroup.get(FormKeys.BOXED_SHIPPING)}
							updateControl={handleUpdateControl}
						/>
					</Box>
				</Box>
			</Box>
		);
	}

	return (
		<Page className={'rsDeintegrationPage'}>
			<PageHeader
				title={'Deintegration'}
				rightNode={
					checklistFormGroup.isModified() && (
						<Box className={'submitButtonContainer'}>
							<Button look={'outlinedPrimary'} onClick={handleCancel}>
								CANCEL
							</Button>
							<Button look={'containedPrimary'} onClick={handleSubmit}>
								Submit Deintegration
							</Button>
						</Box>
					)
				}
			/>
			{serialNumber && (
				<Box className={'warheadBannerContainer'}>
					<WarHeadBanner type={bannerStatus} isDeintegrationCheckPage={true} />
				</Box>
			)}
			<Box className={'assemblyInputContainer'}>
				<Label variant={'subheader1'} weight={'regular'}>
					Scan hardware identifiers to start deintegration
				</Label>
				<SelectableInputText
					control={quickLookupControl}
					updateControl={(updatedControl) => setQuickLookupControl(updatedControl)}
					labelTitle={'Assembly'}
					isSelected={isQuickLookupSelected}
					onBlurOrEnter={handleQuickLookUp}
					onClick={() => setIsQuickLookupSelected(true)}
				/>
				<Box>{serialNumber && renderDeintegrationVerification()}</Box>
			</Box>
		</Page>
	);
};

export default DeintegrationPage;
