import * as React from 'react';
import './ActivityLogPanel.scss';
import { Box, Label } from '@redskytech/framework/ui';
import PaperHeaderBar from '../paperHeaderBar/PaperHeaderBar';
import { DateUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';

interface PartData {
	eventName: string;
	parentPartNumber: string;
	childPartNumber: string;
	parentSerialNumber: string;
	childSerialNumber: string;
	parentName: string;
	childName: string;
	parentHardwareRev: string;
	childHardwareRev: string;
}

interface ActivityLogPanelProps {
	isOpen: boolean;
	onClose: () => void;
	actionLogs: Api.V1.ActionLog.Paged.Get.Res[];
}

const ActivityLogPanel: React.FC<ActivityLogPanelProps> = (props: ActivityLogPanelProps) => {
	const assemblyService = serviceFactory.get('AssemblyService');

	function renderLogDescription(log: Api.V1.ActionLog.Paged.Get.Res) {
		if (!log.eventData) return null;

		switch (log.action) {
			case 'PART_ASSOCIATED':
			case 'PART_DISASSOCIATED': {
				const partData = log.eventData as PartData;

				if (log.details === 'Associated to parent') {
					const hardwareId = assemblyService.generateHardwareId(
						partData.parentPartNumber,
						partData.parentSerialNumber,
						partData.parentHardwareRev
					);
					return `${partData.parentName} ${hardwareId}`;
				} else {
					const hardwareId = assemblyService.generateHardwareId(
						partData.childPartNumber,
						partData.childSerialNumber,
						partData.childHardwareRev
					);
					return `${partData.childName} ${hardwareId}`;
				}
			}
			case 'NOTE_ADDED':
			case 'NOTE_UPDATED': {
				const noteData = log.eventData as { noteData: string };
				return noteData.noteData;
			}
			case 'TEST_RAN':
			case 'TEST_RERAN': {
				const testData = log.eventData as { testName: string; status: string };
				return `Test: ${testData.testName}`;
			}
			default:
				return null;
		}
	}

	function renderActionLogs() {
		return (
			<Box className={'logContainer'}>
				{props.actionLogs.map((log) => (
					<Box className={'logItem'} key={log.id}>
						<Box className={'logIndicator'}>
							<Box className={'logCircle'}></Box>
						</Box>
						<Box className={'logContent'}>
							<Box className={'logItemTitle'}>
								<Label variant={'body1'} weight={'bold'}>
									{log.details}
								</Label>
							</Box>
							<Box className={'logItemDescription'}>
								<Label variant={'body2'} weight={'regular'}>
									{log.eventData &&
										Object.keys(log.eventData).length > 0 &&
										renderLogDescription(log)}
								</Label>
							</Box>
							<Box className={'logItemFooter'}>
								<Label variant={'body2'} weight={'light'}>
									{log.userFirstName} {log.userLastName} - {DateUtils.displayDate(log.createdOn)}{' '}
									{DateUtils.displayTime(log.createdOn)}
								</Label>
							</Box>
						</Box>
					</Box>
				))}
			</Box>
		);
	}

	return (
		<Box className={`rsActivityLogPanel ${props.isOpen ? 'open' : ''}`}>
			<Box className={'overlay'} onClick={props.onClose} />
			<Box className={'panel'}>
				<PaperHeaderBar
					title="Activity"
					rightNode={
						<Box className="close-icon" onClick={props.onClose} style={{ cursor: 'pointer' }}>
							<span className={'rsIcon icon-close'}></span>
						</Box>
					}
				>
					{renderActionLogs()}
				</PaperHeaderBar>
			</Box>
		</Box>
	);
};

export default ActivityLogPanel;
