import * as React from 'react';
import './ActivityLogPaper.scss';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import { Box, Label, Button } from '@redskytech/framework/ui';
import { DateUtils } from '../../../utils/utils';
import { useState } from 'react';
import ActivityLogPanel from '../../../components/activityLogPanel/ActivityLogPanel';
import serviceFactory from '../../../services/serviceFactory';

interface PartData {
	eventName: string;
	parentPartNumber: string;
	childPartNumber: string;
	parentSerialNumber: string;
	childSerialNumber: string;
	parentName: string;
	childName: string;
	parentHardwareRev: string;
	childHardwareRev: string;
}

interface ActivityLogPaperProps {
	actionLogs: Api.V1.ActionLog.Paged.Get.Res[];
}

const ActivityLogPaper: React.FC<ActivityLogPaperProps> = (props: ActivityLogPaperProps) => {
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const assemblyService = serviceFactory.get('AssemblyService');

	function renderLogDescription(log: Api.V1.ActionLog.Paged.Get.Res) {
		if (!log.eventData) return null;

		switch (log.action) {
			case 'PART_ASSOCIATED':
			case 'PART_DISASSOCIATED': {
				const partData = log.eventData as PartData;

				if (log.details === 'Associated to parent') {
					const hardwareId = assemblyService.generateHardwareId(
						partData.parentPartNumber,
						partData.parentSerialNumber,
						partData.parentHardwareRev
					);
					return `${partData.parentName} ${hardwareId}`;
				} else {
					const hardwareId = assemblyService.generateHardwareId(
						partData.childPartNumber,
						partData.childSerialNumber,
						partData.childHardwareRev
					);
					return `${partData.childName} ${hardwareId}`;
				}
			}
			case 'NOTE_ADDED':
			case 'NOTE_UPDATED': {
				const noteData = log.eventData as { noteData: string };
				return noteData.noteData;
			}
			case 'TEST_RAN':
			case 'TEST_RERAN': {
				const testData = log.eventData as { testName: string; status: string };
				return `Test: ${testData.testName}`;
			}
			default:
				return null;
		}
	}

	function renderActionLogs() {
		return (
			<Box className={'logContainer'}>
				{props.actionLogs.map((log) => (
					<Box className={'logItem'} key={log.id}>
						<Box className={'logIndicator'}>
							<Box className={'logCircle'}></Box>
						</Box>
						<Box className={'logContent'}>
							<Box className={'logItemTitle'}>
								<Label variant={'body1'} weight={'bold'}>
									{log.details}
								</Label>
							</Box>
							<Box className={'logItemDescription'}>
								<Label variant={'body2'} weight={'regular'}>
									{log.eventData &&
										Object.keys(log.eventData).length > 0 &&
										renderLogDescription(log)}
								</Label>
							</Box>
							<Box className={'logItemFooter'}>
								<Label variant={'body2'} weight={'light'}>
									{log.userFirstName} {log.userLastName} - {DateUtils.displayDate(log.createdOn)}{' '}
									{DateUtils.displayTime(log.createdOn)}
								</Label>
							</Box>
						</Box>
					</Box>
				))}
			</Box>
		);
	}

	function renderLogView() {
		return (
			<Button look={'outlinedPrimary'} onClick={() => setIsPanelOpen(true)}>
				View Log
			</Button>
		);
	}

	return (
		<>
			<PaperHeaderBar title={'Recent Activity'} className={'rsActivityLogPaper'} rightNode={renderLogView()}>
				{renderActionLogs()}
			</PaperHeaderBar>
			<ActivityLogPanel
				isOpen={isPanelOpen}
				onClose={() => setIsPanelOpen(false)}
				actionLogs={props.actionLogs}
			/>
		</>
	);
};

export default ActivityLogPaper;
