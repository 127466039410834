import * as React from 'react';
import './WarheadWarningPopup.scss';
import { useState } from 'react';
import {
	Box,
	Button,
	Label,
	Popup,
	popupController,
	PopupProps,
	Checkbox,
	RsFormGroup,
	RsFormControl
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import SiteOfficialPopup from '../siteOfficialPopup/SiteOfficialPopup';

enum FormKeys {
	PRESENT_CHECKBOX = 'presentCheckbox'
}

export type WarheadWarningPopupProps = PopupProps;

const WarheadWarningPopup: React.FC<WarheadWarningPopupProps> = (props) => {
	const [confirmedStatus, setConfirmedStatus] = useState<boolean>(false);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl(FormKeys.PRESENT_CHECKBOX, false, [])])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		const checkboxValue = formGroup.get(FormKeys.PRESENT_CHECKBOX).value;
		if (checkboxValue) {
			setConfirmedStatus(true);
		} else {
			setConfirmedStatus(false);
		}
	}

	function handleConfirmation() {
		if (confirmedStatus) {
			popupController.close(WarheadWarningPopup);
			setTimeout(() => {
				popupController.open(SiteOfficialPopup);
			}, 100);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsWarheadWarningPopup'}>
				<PopupHeader
					title={'Confirm [Warhead/LEEFI]'}
					onClose={() => {
						popupController.close(WarheadWarningPopup);
					}}
				/>
				<Box className={'warheadWarningContainer'} display={'grid'} gap={16} margin={'24px 0'}>
					<Label variant={'body1'} weight={'regular'}>
						You have marked the [warhead/LEEFI] as 'Present' on this payload. Are you sure you want to mark
						it as 'Present'?
					</Label>
					<Box className={'checkboxContainer'}>
						<Checkbox
							look={'containedPrimary'}
							labelText={'Yes, the [warhead/LEEFI] is present'}
							control={formGroup.get(FormKeys.PRESENT_CHECKBOX)}
							updateControl={handleUpdateControl}
						/>
					</Box>
				</Box>
				<Box className={'warningButtonContainer'} display={'flex'} justifyContent={'space-between'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.close(WarheadWarningPopup);
						}}
					>
						Cancel
					</Button>
					<Button look={'containedPrimary'} onClick={handleConfirmation} disabled={!confirmedStatus}>
						Confirm
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};

export default WarheadWarningPopup;
