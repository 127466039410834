import {
	Box,
	Checkbox,
	Icon,
	InputText,
	Label,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum,
	Select,
	rsToastify
} from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useIsMounted from '../../customHooks/useIsMounted';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { aurPartNumbers } from '../../services/assembly/assembly.data';
import AssemblyService, { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitChecklistEntry, InspectionResult } from '../../services/kit/IKitService';
import KitService from '../../services/kit/KitService';
import globalState from '../../state/globalState';
import colors from '../../themes/colors.scss?export';
import AssemblyAuditCheck from '../assemblyAuditCheck/AssemblyAuditCheck';
import TestAuditCheck from '../testAuditCheck/TestAuditCheck';
import './KitCheckoutRow.scss';
import { AssemblyTreeTestAuditResult, processPartFamilyTreeAudit } from '../../utils/generateAuditList';

export interface KitCheckoutRowProps {
	HWID: HardwareIdDecoded;
	checklistItems: KitChecklistEntry[];
	removeItem?: (HWID: HardwareIdDecoded) => void;
	globalExpanded?: boolean;
	expansionTrigger?: boolean;
	locked?: boolean;
	excludeFlights?: boolean;
}

const KitCheckoutRow: React.FC<KitCheckoutRowProps> = (props) => {
	const assemblyService = new AssemblyService();
	const kitService = new KitService();
	const [partId, setPartId] = useState<number>(-1);
	const [isExpanded, setIsExpanded] = React.useState(false);
	const kitResults = useRecoilValue<InspectionResult[]>(globalState.inspectionResults);
	const [rowComplete, setRowComplete] = useState<boolean>(false);
	const res = useMemo(() => {
		return kitResults.find(
			(prevRes) =>
				prevRes.HWID.hardwareRevision === props.HWID.hardwareRevision &&
				prevRes.HWID.partNumber === props.HWID.partNumber &&
				prevRes.HWID.serialNumber === props.HWID.serialNumber
		);
	}, [kitResults, props.HWID]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(generateFormGroup());
	const isMounted = useIsMounted();
	const [testResultCnt, setTestResultCnt] = React.useState<number>(0);
	const [auditResultCnt, setAuditResultCnt] = React.useState<number>(0);
	const [testResultList, setTestResultList] = React.useState<string[]>([]);
	const [auditResultList, setAuditResultList] = React.useState<string[]>([]);
	const [groupedItems, setGroupedItems] = useState<{ [key: string]: string[] }>({});
	const [testAuditData, setTestAuditData] = React.useState<AssemblyTreeTestAuditResult | undefined>(undefined);

	useEffect(() => {
		if (partId < 0) return;

		(async () => {
			const partAudit = await processPartFamilyTreeAudit(
				partId,
				props.excludeFlights === undefined ? false : props.excludeFlights
			);
			setTestAuditData(partAudit);
		})();
	}, [partId]);

	useEffect(() => {
		if (props.expansionTrigger === undefined) return;
		if (props.globalExpanded === undefined) return;
		setIsExpanded(props.globalExpanded);
	}, [props.expansionTrigger]);

	useEffect(() => {
		const kitResult = kitResults.find(
			(kit) =>
				kit.HWID.partNumber === props.HWID.partNumber &&
				kit.HWID.serialNumber === props.HWID.serialNumber &&
				kit.HWID.hardwareRevision === props.HWID.hardwareRevision
		);
		let complete = false;
		if (kitResult) {
			complete = kitResult.completed;
		}
		setRowComplete(complete);
	}, [kitResults]);

	useEffect(() => {
		(async function lookupPart() {
			try {
				const part = await ApiRequestV1.getPartByNumbers({
					partNumber: props.HWID.partNumber,
					serialNumber: props.HWID.serialNumber
				});
				if (isMounted) {
					if (part) {
						setPartId(part.id);
						// extract all items in the part checklist that have a "linkedGroupKey" and group the test items by name in lists by linkedGroupKey
						// make an object to contain item groups, where the key is the linkedGroupKey and the value is an array of strings
						const newGroupedItems: { [key: string]: string[] } = {};

						props.checklistItems.forEach((item) => {
							if (item.linkedGroupKey) {
								if (item.linkedGroupKey in newGroupedItems) {
									newGroupedItems[item.linkedGroupKey].push(item.checklistKey);
								} else {
									newGroupedItems[item.linkedGroupKey] = [item.checklistKey];
								}
							}
						});
						setGroupedItems(newGroupedItems);
					}
				}
			} catch (e) {
				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
				console.error(e);
			}
		})();
	}, [props.HWID, props.checklistItems]);

	//useEffect(() => {
	//setFormGroup(
	function generateFormGroup() {
		return new RsFormGroup(
			props.checklistItems.flatMap((item) => {
				const controlArray = [];
				const booleanKey = item.checklistKey;
				const textKey = item.checklistKey + 'Text';
				const selectYesNoKey = item.checklistKey + 'Select';

				controlArray.push(
					new RsFormControl<boolean>(booleanKey, res ? res.data[booleanKey] : false, [
						new RsValidator(RsValidatorEnum.CUSTOM, res ? res.data.checklistKey : false, (control) => {
							return !!control.value;
						})
					])
				);

				if (item.textArea) {
					controlArray.push(
						new RsFormControl<string>(textKey, res ? res.data[textKey] : '', [
							new RsValidator(RsValidatorEnum.CUSTOM, res ? res.data[textKey] : '', (control) => {
								return control.value !== ''; // criteria for the text box validation is that it isn't empty
							})
						])
					);
				}

				if (item.selectYesNo) {
					controlArray.push(
						// by setting the default to an empty string, we can use the empty string later to exit the group check early.
						new RsFormControl(selectYesNoKey, res ? res.data[selectYesNoKey] : '', [
							new RsValidator(RsValidatorEnum.CUSTOM, res ? res.data[selectYesNoKey] : '', (control) => {
								return control.value !== ''; // criteria for the select box validation is that it isn't empty
							})
						])
					);
				}
				return controlArray;
			})
		);
	}
	//}, [props.checklistItems]);

	// Only update verify check boxes when dropdowns are changed for simulation state
	function handleUpdateLinkedGroupSelectBoxes(control: RsFormControl<IRsFormControl>) {
		if (!control.key.endsWith('Select')) return;
		// Strip the 'Select' from the end of the key to get the base key
		const baseKey = control.key.slice(0, -6);

		Object.keys(groupedItems).forEach((groupName) => {
			// group is a now a string list of the base key items.
			const itemGroup = groupedItems[groupName];

			if (!itemGroup.includes(baseKey)) return;

			// generate the list of the values by key, so make a dictionary and add the values to the dictionary by key
			const groupValues: { [key: string]: any } = {};

			// get all of the values from the form group for every key in the group and return if any of them are null
			itemGroup.forEach((key) => {
				groupValues[key] = formGroup.get(key + 'Select').value;
			});
			// apply the current control value to the groupValues so we are up to date
			groupValues[baseKey] = control.value;

			if (Object.values(groupValues).includes('')) return;

			// Our test is whether or not all the select boxes match
			const groupMatched = Object.values(groupValues).every((v, i, a) => v === a[0]);

			// Set all of the corresponding check boxes to the groupMatched value
			itemGroup.forEach((key) => {
				const currentControl = formGroup.get(key);
				currentControl.value = groupMatched;
				setFormGroup((prev) => prev.clone().update(control));
			});
		});
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		handleUpdateLinkedGroupSelectBoxes(control);
		setFormGroup((prev) => prev.clone().update(control));
		const auditSummary = {
			auditErrorCount: auditResultCnt,
			auditErrors: auditResultList,
			testErrorCount: testResultCnt,
			testErrors: testResultList
		};
		kitService.validateKitCheckout(formGroup, props.HWID, auditSummary);
	}

	function renderExpandedRowItems(items: KitChecklistEntry[]) {
		return items.map((item, index) => {
			return (
				<Box key={index} display={'flex'} flexDirection={'row'} gap={16} alignItems={'center'}>
					<Box paddingY={8}>
						<Checkbox
							id={`${item.checklistKey}${index}${props.HWID.serialNumber.replace(' ', '_')}${
								props.HWID.partNumber
							}`}
							labelText={item.label}
							look={'containedPrimary'}
							control={formGroup.get(item.checklistKey)}
							updateControl={handleUpdateControl}
							disabled={item.selectYesNo || (props.locked !== undefined ? props.locked : false)}
						/>
					</Box>
					{item.textArea && (
						<InputText
							id={`text${item.checklistKey}${index}${props.HWID.serialNumber.replace(' ', '_')}${
								props.HWID.partNumber
							}`}
							placeholder={'Version'}
							control={formGroup.get(item.checklistKey + 'Text')}
							updateControl={handleUpdateControl}
							inputMode={'text'}
							disabled={props.locked !== undefined ? props.locked : false}
						></InputText>
					)}
					{item.selectYesNo && (
						<Select<{ label: string; value: string }>
							isCreatable
							className={'selectItem'}
							placeholder={'Select'}
							options={[
								{ label: 'Yes', value: 'Yes' },
								{ label: 'No', value: 'No' }
							]}
							control={formGroup.get(item.checklistKey + 'Select')}
							updateControl={handleUpdateControl}
							isDisabled={props.locked !== undefined ? props.locked : false}
						/>
					)}
				</Box>
			);
		});
	}

	async function handleExpandClick() {
		setIsExpanded(!isExpanded);
	}

	return (
		<Box
			className={'rsKitCheckoutRow'}
			pl={props.HWID.isChild ? '25px' : ''}
			bgColor={props.HWID.isChild ? colors.neutralGrey50 : colors.neutralWhite}
		>
			<Box
				className={'kitCheckoutRowHeader'}
				bgColor={props.HWID.isChild ? colors.neutralGrey50 : colors.neutralWhite}
			>
				<Box className={'collapseColumn'} onClick={handleExpandClick}>
					<Box width={32} height={32} padding={8}>
						<Icon
							iconImg={isExpanded ? 'icon-chevron-down' : 'icon-chevron-up'}
							color={colors.neutralGrey700}
						/>
					</Box>
				</Box>
				<Box className={'serialColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						{props.HWID.serialNumber} - {assemblyService.getLabelFromPartNumber(props.HWID.partNumber)}
					</Label>
				</Box>
				<Box className={'assembliesColumn'}>
					<Box width={32} height={32} padding={8}>
						{!aurPartNumbers.includes(props.HWID.partNumber) &&
							(partId < 0 || !testAuditData ? (
								<Icon iconImg={'icon-pending'} color={colors.neutralGrey700} />
							) : (
								<AssemblyAuditCheck
									partId={partId}
									testAuditData={testAuditData}
									mode={'ICON_SUMMARY'}
									auditStats={(auditcnt, auditErrors) => {
										setAuditResultCnt(auditcnt);
										setAuditResultList(auditErrors);
									}}
								/>
							))}
					</Box>
				</Box>
				<Box className={'testsColumn'}>
					<Box width={32} height={32} padding={8}>
						{!aurPartNumbers.includes(props.HWID.partNumber) &&
							(partId < 0 || !testAuditData ? (
								<Icon iconImg={'icon-pending'} color={colors.neutralGrey700} />
							) : (
								<TestAuditCheck
									partId={partId}
									testAuditData={testAuditData}
									serialNumber={props.HWID.serialNumber}
									mode={'ICON_SUMMARY'}
									testStats={(testcnt, testErrors) => {
										setTestResultCnt(testcnt);
										setTestResultList(testErrors);
									}}
								/>
							))}
					</Box>
				</Box>
				<Box className={'kitCheckColumn'}>
					<Box width={32} height={32} padding={8}>
						{rowComplete ? (
							<Icon iconImg={'icon-check-circle'} color={colors.accentSuccess} />
						) : (
							<Icon iconImg={'icon-pending'} color={colors.neutralGrey700} />
						)}
					</Box>
				</Box>
				<Box className={'deleteColumn'}>
					<Box width={32} height={32} padding={8}>
						{!props.HWID.isChild && (
							<Icon
								cursorPointer
								iconImg={'icon-delete'}
								onClick={(_event) => {
									props.removeItem && props.removeItem(props.HWID);
								}}
								color={colors.neutralGrey700}
							/>
						)}
					</Box>
				</Box>
			</Box>

			<Box className={isExpanded ? 'kitCheckoutBody show' : 'kitCheckoutBody'}>
				{renderExpandedRowItems(props.checklistItems)}
			</Box>
		</Box>
	);
};

export default KitCheckoutRow;
