import * as React from 'react';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import {
	Box,
	Button,
	Icon,
	Label,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../../state/globalState';
import serviceFactory from '../../../../services/serviceFactory';
import LoadingPopup, { LoadingPopupProps } from '../../../../popups/loadingPopup/LoadingPopup';
import { WebUtils } from '../../../../utils/utils';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import { TestKey } from '../FlightTestSection';
import { ArtifactInfo, FlightFileData } from '../../../../services/calibration/ICalibrationService';
import colors from '../../../../themes/colors.scss?export';
import { Link } from '@redskytech/framework/996';
import { ITestCriteria } from '../../../../utils/testCriteria';
import ConfirmPopup, { ConfirmPopupProps } from '../../../../popups/confirmPopup/ConfirmPopup';

interface LoadVideoProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	VIDEO_DATA = 'videoDataUploaded'
}

const LoadVideo: React.FC<LoadVideoProps> = (props) => {
	const TEST_NAME: TestKey = 'loadVideo';
	const testerShimStatus = useRecoilValue(globalState.testerShimStatus);
	const [flightTestFileList, setFlightTestFileList] = useRecoilState<FlightFileData[] | undefined>(
		globalState.flightTestFileData
	);
	const [testArtifacts, _setTestArtifacts] = useRecoilState<ArtifactInfo[]>(globalState.testArtifacts);
	const socketioService = serviceFactory.get('SocketioService');
	const testFixtureService = serviceFactory.get('TestFixtureService');
	// save the loading popup id in state so we can close it later
	const [loadingPopupId, setLoadingPopupId] = useState<number | undefined>(undefined);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [terminatedTestCode, setTerminatedTestCode] = useRecoilState<1 | 2 | undefined>(
		globalState.terminatedTestCode
	);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.VIDEO_DATA, res ? res.data[FormKeys.VIDEO_DATA] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		]);
	}, [res]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		const dataGeneratedResult = testResults.find((res) => res.testName === 'generateData');
		if (dataGeneratedResult) {
			if (isSubmitted) {
				setIsSubmitted(false);
				if (loadingPopupId) {
					popupController.closeById(loadingPopupId);
					setLoadingPopupId(undefined);
				}
				if (dataGeneratedResult.passed) {
					const control = formGroup.clone().get(FormKeys.VIDEO_DATA);
					control.value = true;
					setFormGroup(formGroup.clone().update(control));
					testFixtureService.validateFlightTest(
						formGroup,
						TEST_NAME,
						undefined,
						undefined,
						props.testCriteria
					);
				} else {
					rsToastify.error(
						`Error transcoding video data. ${JSON.stringify(dataGeneratedResult.error)}`,
						'Error Processing Video'
					);
				}
			}
		}
	}, [testResults, props.testCriteria]);

	useEffect(() => {
		if (!terminatedTestCode && terminatedTestCode !== 1) return;
		if (loadingPopupId) {
			popupController.closeById(loadingPopupId);
			setLoadingPopupId(undefined);
		}
		setTestResults((prev) => {
			return [
				...prev,
				{
					testName: 'generateData',
					passed: false,
					continuable: true,
					timeStamp: new Date().toLocaleString([], {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					error: 'Error: Script failure.'
				}
			];
		});
		setTerminatedTestCode(undefined);
	}, [terminatedTestCode]);

	function startScript(file: File) {
		if (testerShimStatus.status !== 'CONNECTED_DATA') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			if (videoFileInput.current) {
				videoFileInput.current.value = '';
			}
			return;
		}
		try {
			setLoadingPopupId(popupController.open<LoadingPopupProps>(LoadingPopup, {}));
			socketioService.runFlightTestScript(file);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Error Generating Data');
			if (videoFileInput.current) {
				videoFileInput.current.value = '';
			}
			popupController.close(LoadingPopup);
			setLoadingPopupId(undefined);
		}
	}

	const videoFileInput = useRef<HTMLInputElement | null>(null);

	function handleVideoFile(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files) return;
		if (e.target.files.length === 0) {
			// if this was empty then the user cancelled
			return;
		}
		const file = e.target.files[0];
		startScript(file);
		// clear the file input, this resets the onchange
		// event so that we can use the same file twice
		e.target.value = '';
		setIsSubmitted(true);
	}

	function handleFileLaunch(file: FlightFileData) {
		if (testerShimStatus.status !== 'CONNECTED_DATA') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			return;
		}
		socketioService.launchFlightLogTab(file.filePath);
	}

	function handlePruneFlightFileList(file: FlightFileData) {
		setFlightTestFileList((prev) => {
			return prev?.filter((f) => f.fileName !== file.fileName);
		});
	}

	function handlePruneAssociatedResult(artifact: ArtifactInfo) {
		_setTestArtifacts((prev) => {
			return prev?.filter((f) => !(f.fileUrl == artifact.fileUrl && f.fileSize == artifact.fileSize));
		});
	}

	function getFlightVideoElements() {
		return [
			...(flightTestFileList !== undefined
				? flightTestFileList.map((file, index) => {
						if (!file.fileName.match(/\.(mp4|mov|avi|flv|wmv|mkv|webm|ts)$/)) {
							return <Box />;
						}
						let videoUrl = '';
						// If it was a mp4 file, then we can create a video element.`s
						if (file.fileName.endsWith('mp4')) {
							videoUrl = `http://localhost:8989/flightLogsTmp/${file.fileName}`;
						}

						return (
							<Box key={`${file.fileName}_${index}`} alignItems={'center'} gap={8}>
								{videoUrl !== '' && (
									<video controls width="500">
										<source src={videoUrl} type="video/mp4" />
									</video>
								)}
								<Box display={'flex'}>
									<Link
										path={file.filePath}
										onClick={() => {
											handleFileLaunch(file);
										}}
									>
										<Box flexGrow={1}>{file.fileName}</Box>
									</Link>
									<Icon
										iconImg={'icon-delete'}
										color={colors.accentError}
										cursorPointer={true}
										onClick={() => {
											popupController.open<ConfirmPopupProps>(ConfirmPopup, {
												title: 'Remove File',
												message:
													'This file has not been saved or uploaded, Are you sure you want to remove it from this test result?',
												confirmButtonText: 'Remove',
												closeButtonText: 'Cancel',
												onConfirm: () => {
													handlePruneFlightFileList(file);
												},
												onCancel: () => {
													// do nothing
												}
											});
										}}
									/>
								</Box>
							</Box>
						);
				  })
				: []),
			...(testArtifacts !== undefined
				? testArtifacts.map((artifact, index) => {
						const fileName = artifact.fileUrl.split('/').pop();
						if (fileName === undefined || !fileName.match(/\.(mp4|ts)$/)) {
							return null;
						}
						return (
							<Box>
								{fileName && fileName.endsWith('mp4') && (
									<video controls width="500">
										<source src={artifact.fileUrl} type={'video/mp4'} />
									</video>
								)}
								<Link
									key={index}
									path={
										fileName && fileName.includes('pdf')
											? `${artifact.fileUrl}?download=true`
											: artifact.fileUrl
									}
									external
									target={'blank'}
								>
									<Label variant={'body1'} weight={'regular'} color={colors.primaryBlue700} underline>
										{fileName}
									</Label>
								</Link>
								<Icon
									iconImg={'icon-delete'}
									color={colors.accentError}
									cursorPointer={true}
									onClick={() => {
										popupController.open<ConfirmPopupProps>(ConfirmPopup, {
											title: 'Disassociate File',
											message:
												'This file has already been attached and uploaded, are you sure you want to remove it from this test result?',
											confirmButtonText: 'Remove',
											closeButtonText: 'Cancel',
											onConfirm: () => {
												handlePruneAssociatedResult(artifact);
											},
											onCancel: () => {
												// do nothing
											}
										});
									}}
								/>
							</Box>
						);
				  })
				: [])
		];
	}

	return (
		<Box className={'rsLoadVideo'}>
			<input
				ref={videoFileInput}
				type={'file'}
				style={{ display: 'none' }}
				accept={'.mp4, .mov, .avi, .flv, .wmv, .mkv, .webm, .ts'}
				onChange={handleVideoFile}
			/>
			<Button
				look={'containedPrimary'}
				onClick={() => {
					videoFileInput.current?.click();
				}}
				mt={16}
			>
				Add Video File
			</Button>
			<Box className={'videoElementList'} mt={16}>
				<Box display={'flex'} flexDirection={'column'} gap={8}>
					{getFlightVideoElements()}
				</Box>
			</Box>
			<Box mt={16} />
		</Box>
	);
};
export default LoadVideo;
