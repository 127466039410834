import { rsToastify } from '@redskytech/framework/ui/index.js';
import { ApiRequestV1 } from '../generated/apiRequests';
import { fcuTestKeys } from '../pages/flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../pages/flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../pages/magTestPage/MagTestSection';
import {
	assemblyStructure,
	assemblyAuditExceptionsStructure,
	fcuPartNumbers,
	livePayloadFlightTestPartNumbers,
	trainerPayloadPartNumbers,
	baseParts
} from '../services/assembly/assembly.data';
import { BasePartType, PartAssemblyType } from '../services/assembly/IAssemblyService';
import serviceFactory from '../services/serviceFactory';
import { optionalTestFixtures, TestFixtureType } from '../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../services/testFixture/testFixture.data';
import { TabletChecklistKeys } from '../pages/tabletSetupPage/TabletSetupPage';

export interface AuditTestResultSummary {
	group: string;
	tests: {
		testName: string;
		status: 'PASS' | 'FAIL' | 'INCOMPLETE' | 'MISSING';
		createdOn: string;
		testResultId: number;
	}[];
}
// TODO: ADD the audit of the assembly completion info to this function as well.
export interface AssemblyTestAuditResult {
	assemblyName: string;
	assemblyPartNumber: string;
	assemblySerialNumber: string;
	assemblyId: number;
	testInfo: AuditTestResultSummary[];
	failedTests: string[];
	missingTests: string[];
	incompleteTests: string[];
	testsComplete: boolean;
	issueCount: number;
	failedCount: number;
}

export interface AssemblyInfo {
	partNumber: string;
	serialNumber: string;
	assemblyId: number;
	present: boolean;
}

export interface AssemblyAuditResult {
	assemblyName: string;
	assemblyType: PartAssemblyType | BasePartType;
	assemblyPartNumber: string;
	assemblySerialNumber: string;
	assemblyId: number;
	children: AssemblyInfo[];
	assemblyIssues: 0;
	missingAssemblies: string[];
	assemblyComplete: boolean;
}

export interface AssemblyTreeTestAuditResult {
	parentAssemblyId: number;
	parentAssemblyPartNumber: string;
	parentAssemblySerialNumber: string;
	testInfo: AssemblyTestAuditResult[];
	assemblyInfo: AssemblyAuditResult[];
	allTestsComplete: boolean;
	allAssembliesPresent: boolean;
	testIssueCount: number;
	testFailedCount: number;
	assemblyIssueCount: number;
}

export interface TestAuditCheckState {
	type: 'CLEAR' | 'ADD';
	payload?: string[];
}

export interface ChecklistItem {
	key: string;
	value: boolean | string;
}

export interface AssemblyTasksChecked {
	checklist: ChecklistItem[];
}

async function checkTestsForSingleAssembly(
	partId: number,
	partNumber: string,
	serialNumber: string,
	excludeFlightTests: boolean = false
): Promise<AssemblyTestAuditResult | undefined> {
	const assemblyService = serviceFactory.get('AssemblyService');

	let discoveredAssemblyType: PartAssemblyType | BasePartType | undefined;

	if (partNumber === baseParts['TABLET'].partNumbers[0] && serialNumber) {
		return await checkForTabletChecklist(partId, partNumber, serialNumber);
	}

	let testAuditSummary: CustomTypes.TestResultAuditResponse = {
		testSuites: {},
		summary: { incompleteTests: 0, missingTests: 0, failedTests: 0 }
	};
	if (partId > 0) {
		testAuditSummary = await ApiRequestV1.getTestResultAuditSummary({ partId: partId });
	}

	if (!testAuditSummary) {
		rsToastify.error('Error checking assembly tests', 'Error');
		return;
	}

	discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(partNumber);
	if (!discoveredAssemblyType) {
		discoveredAssemblyType = assemblyService.getBasePartTypeFromPartNumber(partNumber);
		if (discoveredAssemblyType) {
			// This is a base component, and has no children, so just return
			return;
		} else {
			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
			return;
		}
	}

	// get the full list of the tests based on the code.
	const officialTestList: string[] = [];
	const thisAssemblyAudit: AssemblyTestAuditResult = {
		assemblyName: assemblyService.getLabelFromPartNumber(partNumber) || '',
		assemblyPartNumber: partNumber,
		assemblySerialNumber: serialNumber,
		assemblyId: partId,
		testsComplete: true,
		testInfo: [],
		failedTests: [],
		missingTests: [],
		incompleteTests: [],
		issueCount: 0,
		failedCount: 0
	};

	const officialTestSuitesList: { [key: string]: string[] } = {};
	if (!discoveredAssemblyType) return;
	if (discoveredAssemblyType && Object.prototype.hasOwnProperty.call(testFixtureTestMap, discoveredAssemblyType)) {
		let fixture: TestFixtureType;

		for (fixture in testFixtureTestMap[discoveredAssemblyType]) {
			if (!fixture) continue;
			if (
				!Object.prototype.hasOwnProperty.call(testFixtureTestMap[discoveredAssemblyType], fixture) ||
				optionalTestFixtures.includes(fixture)
			)
				continue;

			const fixtureName: string = testFixtureTestMap[discoveredAssemblyType][fixture]!.name
				? testFixtureTestMap[discoveredAssemblyType][fixture]!.name
				: 'unknown';

			const fixtureSummary: AuditTestResultSummary = {
				group: fixtureName,
				tests: []
			};

			officialTestSuitesList[fixtureName] = [];
			// get the list of tests for the fixture
			for (const test of testFixtureTestMap[discoveredAssemblyType][fixture]!.tests) {
				if (test.auditRequired) {
					if (test.auditRequiredBy && Object.keys(testAuditSummary.testSuites).includes(fixtureName)) {
						if (new Date(testAuditSummary.testSuites[fixtureName].lastRun) > test.auditRequiredBy) {
							officialTestList.push(test.testName);
							const testRecord =
								testAuditSummary.testSuites[fixtureName]?.testRecords?.[test.testName] || {};

							fixtureSummary.tests.push({
								testName: test.testName,
								status: testRecord.status || 'INCOMPLETE',
								createdOn: testRecord.createdOn || '',
								testResultId: testRecord.testResultId || -1
							});
							officialTestSuitesList[fixtureName].push(test.testName);
						}
					} else {
						officialTestList.push(test.testName);
						if (
							Object.keys(testAuditSummary.testSuites).includes(fixtureName) &&
							Object.keys(testAuditSummary.testSuites[fixtureName].testRecords).includes(test.testName)
						) {
							fixtureSummary.tests.push({
								testName: test.testName,
								status:
									testAuditSummary.testSuites[fixtureName].testRecords[test.testName].status ||
									'INCOMPLETE',
								createdOn:
									testAuditSummary.testSuites[fixtureName].testRecords[test.testName].createdOn || '',
								testResultId:
									testAuditSummary.testSuites[fixtureName].testRecords[test.testName].testResultId ||
									-1
							});
						} else {
							fixtureSummary.tests.push({
								testName: test.testName,
								status: 'INCOMPLETE',
								createdOn: '',
								testResultId: -1
							});
						}
						officialTestSuitesList[fixtureName].push(test.testName);
					}
				}
			}
			if (testFixtureTestMap[discoveredAssemblyType][fixture]!.deprecatedTests !== undefined) {
				for (const test of testFixtureTestMap[discoveredAssemblyType][fixture]!.deprecatedTests!) {
					if (test.auditRequired) {
						if (
							test.auditRequiredBefore &&
							Object.keys(testAuditSummary.testSuites).includes(fixtureName)
						) {
							if (new Date(testAuditSummary.testSuites[fixtureName].lastRun) < test.auditRequiredBefore) {
								officialTestList.push(test.testName);
								fixtureSummary.tests.push({
									testName: test.testName,
									status:
										testAuditSummary.testSuites[fixtureName].testRecords[test.testName].status ||
										'INCOMPLETE',
									createdOn:
										testAuditSummary.testSuites[fixtureName].testRecords[test.testName].createdOn ||
										'',
									testResultId:
										testAuditSummary.testSuites[fixtureName].testRecords[test.testName]
											.testResultId || -1
								});
								officialTestSuitesList[fixtureName].push(test.testName);
							}
						}
					}
				}
			}
			thisAssemblyAudit.testInfo.push(fixtureSummary);
		}
	}

	if (!excludeFlightTests) {
		// add payload appropriate flight tests
		if (trainerPayloadPartNumbers.includes(partNumber)) {
			const fixtureSummary: AuditTestResultSummary = {
				group: 'Flight Test',
				tests: []
			};
			officialTestSuitesList['Flight Test'] = [];
			trainerPayloadTestKeys.forEach((key) => {
				officialTestList.push(key);
				if (
					Object.keys(testAuditSummary.testSuites).includes('Flight Test') &&
					Object.keys(testAuditSummary.testSuites['Flight Test'].testRecords).includes(key)
				) {
					fixtureSummary.tests.push({
						testName: key,
						status: testAuditSummary.testSuites['Flight Test'].testRecords[key].status || 'INCOMPLETE',
						createdOn: testAuditSummary.testSuites['Flight Test'].testRecords[key].createdOn || '',
						testResultId: testAuditSummary.testSuites['Flight Test'].testRecords[key].testResultId || -1
					});
				} else {
					fixtureSummary.tests.push({
						testName: key,
						status: 'INCOMPLETE',
						createdOn: '',
						testResultId: -1
					});
				}
				officialTestSuitesList['Flight Test'].push(key);
			});
			thisAssemblyAudit.testInfo.push(fixtureSummary);
		}

		// add payload appropriate flight tests
		if (livePayloadFlightTestPartNumbers.includes(partNumber)) {
			const fixtureSummary: AuditTestResultSummary = {
				group: 'Flight Test',
				tests: []
			};
			officialTestSuitesList['Flight Test'] = [];
			livePayloadTestKeys.forEach((key) => {
				officialTestList.push(key);
				if (
					Object.keys(testAuditSummary.testSuites).includes('Flight Test') &&
					Object.keys(testAuditSummary.testSuites['Flight Test'].testRecords).includes(key)
				) {
					fixtureSummary.tests.push({
						testName: key,
						status: testAuditSummary.testSuites['Flight Test'].testRecords[key].status || 'INCOMPLETE',
						createdOn: testAuditSummary.testSuites['Flight Test'].testRecords[key].createdOn || '',
						testResultId: testAuditSummary.testSuites['Flight Test'].testRecords[key].testResultId || -1
					});
				} else {
					fixtureSummary.tests.push({
						testName: key,
						status: 'INCOMPLETE',
						createdOn: '',
						testResultId: -1
					});
				}
				officialTestSuitesList['Flight Test'].push(key);
			});
			thisAssemblyAudit.testInfo.push(fixtureSummary);
		}

		// add vehicle appropriate flight tests and mag cal
		if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			let fixtureSummary: AuditTestResultSummary = {
				group: 'Flight Test',
				tests: []
			};
			officialTestSuitesList['Flight Test'] = [];
			vehicleTestKeys.forEach((key) => {
				officialTestList.push(key);
				if (
					Object.keys(testAuditSummary.testSuites).includes('Flight Test') &&
					Object.keys(testAuditSummary.testSuites['Flight Test'].testRecords).includes(key)
				) {
					fixtureSummary.tests.push({
						testName: key,
						status: testAuditSummary.testSuites['Flight Test'].testRecords[key].status || 'INCOMPLETE',
						createdOn: testAuditSummary.testSuites['Flight Test'].testRecords[key].createdOn || '',
						testResultId: testAuditSummary.testSuites['Flight Test'].testRecords[key].testResultId || -1
					});
				} else {
					fixtureSummary.tests.push({
						testName: key,
						status: 'INCOMPLETE',
						createdOn: '',
						testResultId: -1
					});
				}
				officialTestSuitesList['Flight Test'].push(key);
			});
			thisAssemblyAudit.testInfo.push(fixtureSummary);
			fixtureSummary = {
				group: 'Magnetometer Calibration',
				tests: []
			};
			officialTestSuitesList['Magnetometer Calibration'] = [];
			magTestKeys.forEach((key) => {
				officialTestList.push(key);
				if (
					Object.keys(testAuditSummary.testSuites).includes('Magnetometer Calibration') &&
					Object.keys(testAuditSummary.testSuites['Magnetometer Calibration'].testRecords).includes(key)
				) {
					fixtureSummary.tests.push({
						testName: key,
						status:
							testAuditSummary.testSuites['Magnetometer Calibration'].testRecords[key].status ||
							'INCOMPLETE',
						createdOn:
							testAuditSummary.testSuites['Magnetometer Calibration'].testRecords[key].createdOn || '',
						testResultId:
							testAuditSummary.testSuites['Magnetometer Calibration'].testRecords[key].testResultId || -1
					});
				} else {
					fixtureSummary.tests.push({
						testName: key,
						status: 'INCOMPLETE',
						createdOn: '',
						testResultId: -1
					});
				}
				officialTestSuitesList['Magnetometer Calibration'].push(key);
			});
			thisAssemblyAudit.testInfo.push(fixtureSummary);
		}

		// add fcu tests
		if (fcuPartNumbers.includes(partNumber)) {
			const fixtureSummary: AuditTestResultSummary = {
				group: 'Flight Test',
				tests: []
			};
			officialTestSuitesList['Flight Test'] = [];
			fcuTestKeys.forEach((key) => {
				officialTestList.push(key);
				if (
					Object.keys(testAuditSummary.testSuites).includes('Flight Test') &&
					Object.keys(testAuditSummary.testSuites['Flight Test'].testRecords).includes(key)
				) {
					fixtureSummary.tests.push({
						testName: key,
						status: testAuditSummary.testSuites['Flight Test'].testRecords[key].status || 'INCOMPLETE',
						createdOn: testAuditSummary.testSuites['Flight Test'].testRecords[key].createdOn || '',
						testResultId: testAuditSummary.testSuites['Flight Test'].testRecords[key].testResultId || -1
					});
				} else {
					fixtureSummary.tests.push({
						testName: key,
						status: 'INCOMPLETE',
						createdOn: '',
						testResultId: -1
					});
				}
				officialTestSuitesList['Flight Test'].push(key);
			});
			thisAssemblyAudit.testInfo.push(fixtureSummary);
		}
	}

	let anyIncompleteTests = false;
	let anyFailedTests = false;
	let anyMissingTests = false;
	// go through the official test list, and if any of the tests are missing, add them to the missingTests array
	const missingTests: string[] = [];
	const failedTests: string[] = [];
	const incompleteTests: string[] = [];
	for (const suite in officialTestSuitesList) {
		for (const testEntry in officialTestSuitesList[suite]) {
			const currentTestName = officialTestSuitesList[suite][testEntry];
			if (
				!Object.keys(testAuditSummary.testSuites).includes(suite) ||
				!Object.prototype.hasOwnProperty.call(testAuditSummary.testSuites[suite].testRecords, currentTestName)
			) {
				missingTests.push(currentTestName);

				anyMissingTests = true;
			} else if (testAuditSummary.testSuites[suite].testRecords[currentTestName].status === 'FAIL') {
				failedTests.push(currentTestName);
				anyFailedTests = true;
			} else if (testAuditSummary.testSuites[suite].testRecords[currentTestName].status === 'INCOMPLETE') {
				incompleteTests.push(currentTestName);
				anyIncompleteTests = true;
			}
		}
	}

	if (anyIncompleteTests) {
		thisAssemblyAudit.incompleteTests = incompleteTests;
		thisAssemblyAudit.issueCount += incompleteTests.length;
		thisAssemblyAudit.testsComplete = false;
	}
	if (anyFailedTests) {
		thisAssemblyAudit.failedTests = failedTests;
		thisAssemblyAudit.issueCount += failedTests.length;
		thisAssemblyAudit.failedCount += failedTests.length;
		thisAssemblyAudit.testsComplete = false;
	}
	if (anyMissingTests) {
		thisAssemblyAudit.missingTests = missingTests;
		thisAssemblyAudit.issueCount += missingTests.length;
		thisAssemblyAudit.testsComplete = false;
	}

	return thisAssemblyAudit;
}

async function checkRelationshipsForSingleAssembly(
	partIdToCheck: number,
	partNumber: string,
	serialNumber: string,
	children: CustomTypes.FamilyTreeItem[]
) {
	const assemblyService = serviceFactory.get('AssemblyService');

	let discoveredAssemblyType: PartAssemblyType | BasePartType | undefined;

	discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(partNumber);
	if (!discoveredAssemblyType) {
		discoveredAssemblyType = assemblyService.getBasePartTypeFromPartNumber(partNumber);
		if (discoveredAssemblyType) {
			// This is a base component, and has no children, so just return
			return;
		} else {
			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
			return;
		}
	}
	const partLabel = `${assemblyService.getLabelFromPartNumber(partNumber)}:`;
	const currentAssemblyAuditResult: AssemblyAuditResult = {
		assemblyName: partLabel,
		assemblyType: discoveredAssemblyType,
		assemblyPartNumber: partNumber,
		assemblySerialNumber: serialNumber,
		assemblyId: partIdToCheck,
		children: [],
		assemblyIssues: 0,
		missingAssemblies: [],
		assemblyComplete: true
	};

	const assemblyComponents = assemblyStructure[discoveredAssemblyType];

	const assemblyAuditExceptions = assemblyAuditExceptionsStructure[discoveredAssemblyType];

	if (!assemblyComponents) {
		rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
		return;
	}

	// This shouldn't happen unless we have an assembly with no children it is not a base part.
	if (assemblyComponents.length === 0) {
		return;
	}

	// make a dictionary with a key for every item in required subassemblies, all set to false
	const subassemblyChecklist = assemblyComponents.reduce((obj: any, item: PartAssemblyType | BasePartType) => {
		if (item in obj) {
			obj[item]['count']++;
		} else obj[item] = { auditComplete: false, count: 1, auditSkip: false };
		return obj;
	}, {});

	// check the exceptions, and mark auditSkip for them
	if (assemblyAuditExceptions) {
		for (const exception of assemblyAuditExceptions) {
			if (Object.prototype.hasOwnProperty.call(subassemblyChecklist, exception.assembly) && partIdToCheck > 0) {
				// Only query this if it is an "exceptional" assembly
				const res = await ApiRequestV1.getPart({ partId: partIdToCheck });
				// Check the date that the parent assembly was assembled, if the date was older than the date required by set the auditSkip to true
				// it defaults to Date.now for now so we have to specify a date for the new whitelist required items.
				// If we don't set one on the exception for whatever reason, we just skip the audit.
				const associatedDate = new Date(res.associatedOn ? res.associatedOn : Date.now());
				if (associatedDate < exception.dateRequiredBy) {
					subassemblyChecklist[exception.assembly]['auditSkip'] = true;
				}
			}
		}
	}

	const partChildren = children;
	// At this point there should have been children since we had required subassemblies (so we were missing a child.)
	if (!partChildren) {
		currentAssemblyAuditResult.assemblyComplete = false;
		// We can't just return here, we need to add the missing subassemblies to the children array
	} else {
		// for each child, we query it's part number, and if it's in the required subassemblies, we set it to true
		for (const child of partChildren) {
			let childPartAssemblyType: PartAssemblyType | BasePartType | undefined;
			childPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(child.partNumber);
			if (!childPartAssemblyType) {
				childPartAssemblyType = assemblyService.getBasePartTypeFromPartNumber(child.partNumber);
				if (!childPartAssemblyType) {
					rsToastify.error(
						`Unable to recognize child subassembly type. ${child.partNumber}`,
						'Unknown Assembly Type'
					);
				}
			}
			const currentChild: AssemblyInfo = {
				partNumber: child.partNumber,
				serialNumber: child.serialNumber,
				assemblyId: child.id,
				present: true
			};
			currentAssemblyAuditResult.children.push(currentChild);
			if (
				childPartAssemblyType &&
				Object.prototype.hasOwnProperty.call(subassemblyChecklist, childPartAssemblyType)
			) {
				subassemblyChecklist[childPartAssemblyType]['count']--;
				if (subassemblyChecklist[childPartAssemblyType]['count'] === 0) {
					subassemblyChecklist[childPartAssemblyType]['auditComplete'] = true;
				}
				if (subassemblyChecklist[childPartAssemblyType]['count'] < 0) {
					subassemblyChecklist[childPartAssemblyType]['auditComplete'] = false;
					rsToastify.error(
						`Too Many children of type found. ${childPartAssemblyType}`,
						'Part Count Mismatch'
					);
				}
			}

			// special check to deal with main board assemblies
			if (
				childPartAssemblyType &&
				childPartAssemblyType.includes('MAIN_BOARD') &&
				Object.prototype.hasOwnProperty.call(subassemblyChecklist, 'MAIN_BOARD')
			) {
				subassemblyChecklist['MAIN_BOARD']['count']--;
				if (subassemblyChecklist['MAIN_BOARD']['count'] === 0) {
					subassemblyChecklist['MAIN_BOARD']['auditComplete'] = true;
				}
				if (subassemblyChecklist['MAIN_BOARD']['count'] < 0) {
					subassemblyChecklist['MAIN_BOARD']['auditComplete'] = false;
					rsToastify.error(`Too Many children of type found. MAIN_BOARD`, 'Part Count Mismatch');
				}
			}
		}
	}
	let anyMissingAssemblies = false;
	const missingLabels: string[] = [];

	for (const key in subassemblyChecklist) {
		if (!subassemblyChecklist[key]['auditComplete'] && !subassemblyChecklist[key]['auditSkip']) {
			// append all missing subassemblies to the missingSubassemblies array
			if (subassemblyChecklist[key]['count'] > 0) {
				for (let i = 0; i < subassemblyChecklist[key]['count']; i++) {
					if (
						discoveredAssemblyType !== 'MAIN_BOARD_ASSEMBLY' &&
						(discoveredAssemblyType as PartAssemblyType | BasePartType) !== 'MAIN_BOARD' &&
						subassemblyChecklist[key] !== assemblyService.getPartNumberFromPartType('MAIN_BOARD')
					) {
						missingLabels.push(assemblyService.getLabelFromPartType(key));
						const currentChild: AssemblyInfo = {
							partNumber: assemblyService.getPartNumberFromPartType(key),
							serialNumber: '',
							assemblyId: -1,
							present: false
						};
						currentAssemblyAuditResult.children.push(currentChild);
						currentAssemblyAuditResult.assemblyComplete = false;
						anyMissingAssemblies = true;
					}
				}
			} else {
				if (
					discoveredAssemblyType !== 'MAIN_BOARD_ASSEMBLY' &&
					(discoveredAssemblyType as PartAssemblyType | BasePartType) !== 'MAIN_BOARD' &&
					subassemblyChecklist[key] !== assemblyService.getPartNumberFromPartType('MAIN_BOARD')
				) {
					missingLabels.push(assemblyService.getLabelFromPartType(key));
					anyMissingAssemblies = true;
					const currentChild: AssemblyInfo = {
						partNumber: assemblyService.getPartNumberFromPartType(key),
						serialNumber: '',
						assemblyId: -1,
						present: false
					};
					currentAssemblyAuditResult.children.push(currentChild);
					currentAssemblyAuditResult.assemblyComplete = false;
				}
			}
		}
	}
	if (anyMissingAssemblies) {
		//currentAssemblyAuditResult.missingAssemblies.push(partLabel);
		currentAssemblyAuditResult.missingAssemblies = missingLabels;
		currentAssemblyAuditResult.assemblyIssues += missingLabels.length;
		currentAssemblyAuditResult.assemblyComplete = false;
	}
	return currentAssemblyAuditResult;
}

async function checkForTabletChecklist(partId: number, partNumber: string, serialNumber: string) {
	const assemblyService = serviceFactory.get('AssemblyService');
	try {
		const res = await ApiRequestV1.getPartChecklist({
			partNumber: partNumber,
			serialNumber
		});

		if (!res) {
			return;
		}

		const thisAssemblyAudit: AssemblyTestAuditResult = {
			assemblyName: assemblyService.getLabelFromPartNumber(partNumber) || '',
			assemblyPartNumber: partNumber,
			assemblySerialNumber: serialNumber,
			assemblyId: partId,
			testsComplete: true,
			testInfo: [],
			failedTests: [],
			missingTests: [],
			incompleteTests: [],
			issueCount: 0,
			failedCount: 0
		};
		const fixtureSummary: AuditTestResultSummary = {
			group: 'Tablet Checklist',
			tests: []
		};
		const parsed: AssemblyTasksChecked =
			res.assemblyTasksChecked && JSON.parse(res.assemblyTasksChecked.replace(/^"(.*)"$/, '$1'));
		if (parsed) {
			const checkListItems = parsed.checklist.filter(
				(listItem: ChecklistItem) => typeof listItem.value === 'boolean'
			);

			for (const checkListItem of checkListItems) {
				fixtureSummary.tests.push({
					testName: checkListItem.key,
					status: checkListItem.value ? 'PASS' : 'INCOMPLETE',
					createdOn: res.createdOn || '',
					testResultId: -2
				});

				if (!checkListItem.value) {
					thisAssemblyAudit.incompleteTests.push(checkListItem.key);
				}
			}
			thisAssemblyAudit.testsComplete = checkListItems.every((listItem: ChecklistItem) => listItem.value);

			thisAssemblyAudit.incompleteTests = checkListItems
				.filter((listItem: ChecklistItem) => !listItem.value)
				.map((listItem: ChecklistItem) => listItem.key);
		} else {
			thisAssemblyAudit.testsComplete = false;
			// extract the currently defined checklist and add it to the incomplete tests list
			for (const checkListItem in TabletChecklistKeys) {
				fixtureSummary.tests.push({
					testName: TabletChecklistKeys[checkListItem],
					status: 'INCOMPLETE',
					createdOn: '',
					testResultId: -1
				});
				thisAssemblyAudit.incompleteTests.push(TabletChecklistKeys[checkListItem]);
				thisAssemblyAudit.issueCount++;
				thisAssemblyAudit.testsComplete = false;
			}
		}
		thisAssemblyAudit.testInfo.push(fixtureSummary);
		return thisAssemblyAudit;
	} catch (err) {
		console.error(err);
		return;
	}
}

function findCorrectChild(
	targetItem: CustomTypes.FamilyTreeItem,
	partId: number
): CustomTypes.FamilyTreeItem | undefined {
	if (targetItem.id === partId) {
		return targetItem;
	}
	for (const child of targetItem.children) {
		const tmpTargetItem = findCorrectChild(child, partId);
		if (tmpTargetItem) {
			return tmpTargetItem;
		}
	}
	return;
}

export async function processPartFamilyTreeAudit(partId: number, excludeFlights: boolean = false) {
	const item = await ApiRequestV1.getPartFamilyTree({ partId });

	if (!item) return;
	if (!item.familyTree) return;
	let targetItem = item.familyTree;

	// move Down the item.familyTree until we get to the partId we started the audit on.
	// We can't assume a linear structure, so we have to search for each branch of each child
	const tmpTargetItem = findCorrectChild(targetItem, partId);
	if (tmpTargetItem) {
		targetItem = tmpTargetItem;
	}
	//TreeList

	const masterAuditTestList: AssemblyTreeTestAuditResult = {
		parentAssemblyId: targetItem.id,
		parentAssemblyPartNumber: targetItem.partNumber,
		parentAssemblySerialNumber: targetItem.serialNumber,
		testInfo: [],
		assemblyInfo: [],
		allAssembliesPresent: true,
		allTestsComplete: true,
		assemblyIssueCount: 0,
		testIssueCount: 0,
		testFailedCount: 0
	};

	await processTree(targetItem, masterAuditTestList, excludeFlights);

	return masterAuditTestList;
}

async function processTree(
	targetItem: CustomTypes.FamilyTreeItem,
	masterAuditTestList: AssemblyTreeTestAuditResult,
	excludeFlights: boolean = false
) {
	// Process this item, as well as each of it's children.
	const assemblyService = serviceFactory.get('AssemblyService');
	const testItemInfo = await checkTestsForSingleAssembly(
		targetItem.id,
		targetItem.partNumber,
		targetItem.serialNumber,
		excludeFlights
	);
	if (testItemInfo) {
		masterAuditTestList.testInfo.push(testItemInfo);
		masterAuditTestList.testIssueCount += testItemInfo.issueCount;
		masterAuditTestList.testFailedCount += testItemInfo.failedCount;
		if (!testItemInfo.testsComplete) {
			masterAuditTestList.allTestsComplete = false;
		}
	}

	const assemblyItemInfo = await checkRelationshipsForSingleAssembly(
		targetItem.id,
		targetItem.partNumber,
		targetItem.serialNumber,
		targetItem.children
	);
	if (assemblyItemInfo) {
		masterAuditTestList.assemblyInfo.push(assemblyItemInfo);
		masterAuditTestList.assemblyIssueCount += assemblyItemInfo.assemblyIssues;
		if (!assemblyItemInfo.assemblyComplete) {
			masterAuditTestList.allAssembliesPresent = false;
			for (const child of assemblyItemInfo.children) {
				if (child.present) continue;
				const missingAssembly = {
					id: -1,
					name: assemblyService.getLabelFromPartNumber(child.partNumber) || '',
					partNumber: child.partNumber,
					serialNumber: '',
					hardwareRev: '',
					children: []
				};
				if (
					assemblyItemInfo.assemblyType !== 'MAIN_BOARD_ASSEMBLY' &&
					assemblyItemInfo.assemblyType !== 'MAIN_BOARD' &&
					targetItem.partNumber !== assemblyService.getPartNumberFromPartType('MAIN_BOARD')
				) {
					await processTree(missingAssembly, masterAuditTestList, excludeFlights);
				}
			}
		}
	}

	for (const child of targetItem.children) {
		if (
			assemblyItemInfo?.assemblyType !== 'MAIN_BOARD_ASSEMBLY' &&
			assemblyItemInfo?.assemblyType !== 'MAIN_BOARD' &&
			child.partNumber !== assemblyService.getPartNumberFromPartType('MAIN_BOARD')
		) {
			await processTree(child, masterAuditTestList, excludeFlights);
		}
	}
}
