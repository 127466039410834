import {
	Box,
	Button,
	Icon,
	Label,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify
} from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import FlightTestItem from '../../../components/flightTestItem/FlightTestItem';
import PageHeader from '../../../components/pageHeader/PageHeader';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import StatusChip from '../../../components/statusChip/StatusChip';
import useWarnOnUnsavedChanges from '../../../customHooks/useWarnOnUnsavedChanges';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import {
	autoCreateableAssemblyPartNumbers,
	fcuPartNumbers,
	livePayloadPartNumbers,
	payloadPartNumbers,
	trainerPayloadPartNumbers,
	vehiclePartNumbers
} from '../../../services/assembly/assembly.data';
import { ArtifactInfo, FlightFileData } from '../../../services/calibration/ICalibrationService';
import serviceFactory from '../../../services/serviceFactory';
import { TestResult } from '../../../services/testFixture/ITestFixtureService';
import globalState from '../../../state/globalState';
import { ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import { TestStatus } from '../../testDetailsPage/TestDetailsPage';
import './FlightTestSection.scss';
import {
	vehicleFlightTestData,
	vehicleFlightTestDataNoVideo,
	vehicleInFlightTestData,
	vehiclePostFlightTestData,
	vehiclePostFlightTestDataNoVideo,
	vehiclePreFlightTestData,
	VehicleTestKey
} from './VehicleFlightTestList';

import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import debounce from 'lodash.debounce';
import CommentBox from '../../../components/commentBox/CommentBox';
import AddCommentPopup, { AddCommentPopupProps } from '../../../popups/addCommentPopup/AddCommentPopup';
import WarningPopup, { WarningPopupProps } from '../../../popups/warningPopup/WarningPopup';
import { fcuFlightTestData, fcuInFlightTestData, fcuPreFlightTestData, FcuTestKey } from './FcuFlightTestList';
import {
	livePayloadFlightTestData,
	livePayloadFlightTestDataNoVideo,
	livePayloadInFlightTestData,
	livePayloadPostFlightTestData,
	livePayloadPostFlightTestDataNoVideo,
	livePayloadPreFlightTestData,
	LivePayloadTestKey
} from './LivePayloadFlightTestList';
import {
	trainerPayloadFlightTestData,
	trainerPayloadFlightTestDataNoVideo,
	trainerPayloadInFlightTestData,
	trainerPayloadPostFlightTestData,
	trainerPayloadPostFlightTestDataNoVideo,
	trainerPayloadPreFlightTestData,
	TrainerPayloadTestKey
} from './TrainerPayloadFlightTestList';
import LiveRunPreflight from './flightTests/LiveRunPreflight';
import RunPreflight from './flightTests/RunPreflight';

import trainerPayloadFlightTestCriteria from '../flightTestCriteria/trainerPayloadFlightTestCriteria.json';
import livePayloadFlightTestCriteria from '../flightTestCriteria/livePayloadFlightTestCriteria.json';
import { extractTestCriteriaList } from '../../../utils/testCriteria';
import router from '../../../utils/router';

export interface FlightLogUploadRequest {
	filePath: string;
	url: string;
	mimeType: string;
}

interface MessageUploadSectionProps {
	hwid: HardwareIdDecoded;
	testId?: string;
	testRecord?: Api.V1.TestResult.Get.Res;
	onDone: () => void;
}

export type TestKey = TrainerPayloadTestKey | LivePayloadTestKey | VehicleTestKey | FcuTestKey;

enum FormKeys {
	ASSOCIATED_ASSEMBLY_1 = 'associatedAssembly',
	ASSOCIATED_ASSEMBLY_2 = 'associatedAssembly2',
	ASSOCIATED_BATTERIES = 'associatedBattery'
}

const FlightTestSection: React.FC<MessageUploadSectionProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const [isModified, setIsModified] = useState<boolean>(false);
	const [selectedTest, setSelectedTest] = useState<TestKey>('cameraInspection');
	const [connectionGuid, setConnectionGuid] = useState<string>(() => StringUtils.generateGuid());
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [_testArtifacts, setTestArtifacts] = useRecoilState<ArtifactInfo[]>(globalState.testArtifacts);

	const [initialTestResults, _setInitialTestResults] = useState<TestResult[]>(testResults);
	const [initialBatteryList, setInitialBatteryList] = useState<string[]>([]);
	const [fileData, setFileData] = useRecoilState<FlightFileData[] | undefined>(globalState.flightTestFileData);
	const [preFlightTestData, setPreFlightTestData] = useState<any>(vehiclePreFlightTestData);
	const [inFlightTestData, setInFlightTestData] = useState<any>(vehicleInFlightTestData);
	const [postFlightTestData, setPostFlightTestData] = useState<any>(vehiclePostFlightTestData);
	const [flightTestData, setFlightTestData] = useState<any>(vehicleFlightTestData);
	const [showPostFlightSection, setShowPostFlightSection] = useState<boolean>(true);
	const [flightLabel, setFlightLabel] = useState<string>('Vehicle Flight Test');
	const [associatedBatteries, setAssociatedBatteries] = useState<string[]>([]);
	const [comments, setComments] = useRecoilState<{ testName: string; comment: string }[]>(globalState.testComments);
	const [dataGeneratedResultList, setDataGeneratedResultList] = useState<TestResult[]>([]);
	const [acknowledgedSerialMismatch, setAcknowledgedSerialMismatch] = useState<boolean>(false);
	const [associatedAssembliesFormGroup, setAssociatedAssembliesFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.ASSOCIATED_ASSEMBLY_1, '', []),
			new RsFormControl<string>(FormKeys.ASSOCIATED_ASSEMBLY_2, '', [])
		])
	);

	useWarnOnUnsavedChanges(isModified);

	useEffect(() => {
		// Reset when we first mount
		if (props.testId === undefined) {
			setTestResults([]);
			setComments([]);
			setTestArtifacts([]);
			setFileData(undefined);
		}
		if (props.testRecord !== undefined) {
			setConnectionGuid(props.testRecord.connectionGuid);
			try {
				props.testRecord.partsUsedInTest.forEach((part) => {
					if (autoCreateableAssemblyPartNumbers.includes(part.partPartNumber)) {
						setAssociatedBatteries((prev) => [
							...prev.filter(Boolean),
							assemblyService.generateHardwareId(
								part.partPartNumber,
								part.partSerialNumber,
								part.partHardwareRev
							)
						]);
						setInitialBatteryList((prev) => [
							...prev.filter(Boolean),
							assemblyService.generateHardwareId(
								part.partPartNumber,
								part.partSerialNumber,
								part.partHardwareRev
							)
						]);
					}

					if (fcuPartNumbers.includes(part.partPartNumber)) {
						setAssociatedAssembliesFormGroup((prev) => {
							const currentGroup = prev.clone();
							currentGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_2).value = assemblyService.generateHardwareId(
								part.partPartNumber,
								part.partSerialNumber,
								part.partHardwareRev
							);
							currentGroup.updateInitialValues();
							return currentGroup;
						});
					}

					if (
						payloadPartNumbers.includes(part.partPartNumber) ||
						vehiclePartNumbers.includes(part.partPartNumber)
					) {
						setAssociatedAssembliesFormGroup((prev) => {
							const currentGroup = prev.clone();
							currentGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_1).value = assemblyService.generateHardwareId(
								part.partPartNumber,
								part.partSerialNumber,
								part.partHardwareRev
							);
							currentGroup.updateInitialValues();
							return currentGroup;
						});
					}

					// update the other associated assembly fields
					// somehow. I don't know how to do this.
				});
			} catch (e) {
				console.error(e);
				rsToastify.error('Part not found.', 'Invalid Part Number');
			}

			// update the form controls for the associated assemblies
		}
		return () => {
			// clean up when we unmount.
			setTestResults([]);
			setComments([]);
			setTestArtifacts([]);
			// clear the router params
			router.updateQueryParams({});
			setFileData(undefined);
		};
	}, []);

	useEffect(() => {
		const socketIoService = serviceFactory.get('SocketioService');
		if (props.hwid.partNumber && trainerPayloadPartNumbers.includes(props.hwid.partNumber)) {
			setPreFlightTestData(trainerPayloadPreFlightTestData);
			const updatedPayloadInFlightTestData = trainerPayloadInFlightTestData;
			let payloadPartType = assemblyService.getPartAssemblyTypeFromPartNumber(props.hwid.partNumber);
			const trainerPayloadTestCriteriaList = extractTestCriteriaList(
				trainerPayloadFlightTestCriteria,
				'RunPreflight'
			);
			if (payloadPartType === undefined) payloadPartType = 'TRAINER_PAYLOAD_ASSEMBLY';
			updatedPayloadInFlightTestData.runPreflight = (
				<RunPreflight
					key={'updatedPreflight'}
					targetPayloadType={payloadPartType}
					testCriteria={trainerPayloadTestCriteriaList}
				/>
			);

			setInFlightTestData(updatedPayloadInFlightTestData);
			if (socketIoService.getVideoFeatureAvailable()) {
				const updatedPayloadFlightTestData = trainerPayloadFlightTestData;
				updatedPayloadFlightTestData.runPreflight = (
					<RunPreflight
						key={'updatedPreflight'}
						targetPayloadType={payloadPartType}
						testCriteria={trainerPayloadTestCriteriaList}
					/>
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(trainerPayloadPostFlightTestData);
			} else {
				const updatedPayloadFlightTestData = trainerPayloadFlightTestDataNoVideo;
				updatedPayloadFlightTestData.runPreflight = (
					<RunPreflight
						key={'updatedPreflight'}
						targetPayloadType={payloadPartType}
						testCriteria={trainerPayloadTestCriteriaList}
					/>
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(trainerPayloadPostFlightTestDataNoVideo);
			}
			setFlightLabel('Payload Flight Test');
			if (props.testRecord === undefined) {
				setTestResults([]);
				setComments([]);
				setTestArtifacts([]);
				setFileData(undefined);
			}
		} else if (props.hwid.partNumber && livePayloadPartNumbers.includes(props.hwid.partNumber)) {
			setPreFlightTestData(livePayloadPreFlightTestData);
			const updatedPayloadInFlightTestData = livePayloadInFlightTestData;

			let payloadPartType = assemblyService.getPartAssemblyTypeFromPartNumber(props.hwid.partNumber);
			const livePayloadTestCriteriaList = extractTestCriteriaList(livePayloadFlightTestCriteria, 'RunPreflight');

			if (payloadPartType === undefined) payloadPartType = 'INERT_FRAG_PAYLOAD_ASSEMBLY';
			updatedPayloadInFlightTestData.runPreflight = (
				<LiveRunPreflight
					key={'liveUpdatedPreflight'}
					targetPayloadType={payloadPartType}
					testCriteria={livePayloadTestCriteriaList}
				/>
			);

			setInFlightTestData(updatedPayloadInFlightTestData);
			if (socketIoService.getVideoFeatureAvailable()) {
				const updatedPayloadFlightTestData = livePayloadFlightTestData;
				updatedPayloadFlightTestData.runPreflight = (
					<LiveRunPreflight
						key={'liveUpdatedPreflight'}
						targetPayloadType={payloadPartType}
						testCriteria={livePayloadTestCriteriaList}
					/>
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(livePayloadPostFlightTestData);
			} else {
				const updatedPayloadFlightTestData = livePayloadFlightTestDataNoVideo;
				updatedPayloadFlightTestData.runPreflight = (
					<LiveRunPreflight
						key={'liveUpdatedPreflight'}
						targetPayloadType={payloadPartType}
						testCriteria={livePayloadTestCriteriaList}
					/>
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(livePayloadPostFlightTestDataNoVideo);
			}
			setFlightLabel('Live Payload Flight Test');
			if (props.testId === undefined) {
				setTestResults([]);
				setComments([]);
				setTestArtifacts([]);
				setFileData(undefined);
			}
		} else if (props.hwid.partNumber && fcuPartNumbers.includes(props.hwid.partNumber)) {
			setShowPostFlightSection(false);
			setFlightTestData(fcuFlightTestData);
			setPreFlightTestData(fcuPreFlightTestData);
			setInFlightTestData(fcuInFlightTestData);
			setPostFlightTestData([]);
			setFlightLabel('FCU Flight Test');
			if (props.testId === undefined) {
				setTestResults([]);
				setComments([]);
				setTestArtifacts([]);
				setFileData(undefined);
			}
		} else if (props.hwid.partNumber && vehiclePartNumbers.includes(props.hwid.partNumber)) {
			if (!socketIoService.getVideoFeatureAvailable()) {
				setFlightTestData(vehicleFlightTestDataNoVideo);
				setPostFlightTestData(vehiclePostFlightTestDataNoVideo);
			}
			setFlightLabel('Vehicle Flight Test');
			if (props.testId === undefined) {
				setTestResults([]);
				setComments([]);
				setTestArtifacts([]);
				setFileData(undefined);
			}
		}
	}, [props.hwid.partNumber]);

	useEffect(() => {
		// get the list of dataGenerated results/
		const localDataGenerated = testResults.filter((test) => test.testName === 'generateData');

		// IF it doesn't match the current list, update it.
		if (localDataGenerated.length !== dataGeneratedResultList.length) {
			setDataGeneratedResultList(localDataGenerated);
			setAcknowledgedSerialMismatch(false);
		}
	}, [testResults]);

	useEffect(() => {
		if (!props.hwid.partNumber) return;
		if (acknowledgedSerialMismatch) return;

		if (dataGeneratedResultList.length > 0 && !acknowledgedSerialMismatch) {
			dataGeneratedResultList.forEach((dataGeneratedResult) => {
				if (dataGeneratedResult.data.acknowledged === undefined) {
					if (dataGeneratedResult.passed) {
						// Let's do a quick check of the resulting data.
						const isPayloadSerial =
							payloadPartNumbers.includes(props.hwid.partNumber) &&
							dataGeneratedResult.data.payloadSerial;
						const isVehicleSerial =
							vehiclePartNumbers.includes(props.hwid.partNumber) &&
							dataGeneratedResult.data.vehicleSerial;

						// check to see if a comparison is needed at all.
						if (isPayloadSerial || isVehicleSerial) {
							// extract the right serial to compare with.
							let detectedSerial = '';
							if (isVehicleSerial) detectedSerial = dataGeneratedResult.data.vehicleSerial;
							else if (isPayloadSerial) detectedSerial = dataGeneratedResult.data.payloadSerial;
							// if the serials don't match, show the warning popup message.
							if (props.hwid.serialNumber !== detectedSerial && detectedSerial !== '') {
								const popupId = popupController.open<WarningPopupProps>(WarningPopup, {
									title: 'Serial Mismatch',
									message: `File Attached \n ${dataGeneratedResult.data?.inputFile.replace(
										/^.*[\\\/]/,
										''
									)} \n does not have the same serial number as the unit under test. Received ${detectedSerial}, expected ${
										props.hwid.serialNumber
									}.`,
									confirmButtonText: 'Continue',
									onConfirm: () => {
										setAcknowledgedSerialMismatch(true);
									}
								});
								return () => {
									if (popupId) popupController.closeById(popupId);
								};
							}
						}
					}
				}
			});
		}
	}, [dataGeneratedResultList, props.hwid.partNumber, props.hwid.serialNumber]);

	useEffect(() => {
		if (
			!associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_1).isAtInitialValue() ||
			!associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_2).isAtInitialValue() ||
			!areListsEmptyOrEqual(associatedBatteries, initialBatteryList) ||
			initialTestResults !== testResults
		) {
			setIsModified(true);
		} else {
			setIsModified(false);
		}
		// I can't really clear is modified here, because the tests changing can affect modified, but lets try this.
	}, [associatedAssembliesFormGroup, associatedBatteries, testResults]);

	function areListsEmptyOrEqual<T>(list1: T[], list2: T[]): boolean {
		// Check if both are empty
		if (list1.length === 0 && list2.length === 0) return true;

		// Check if lengths are different
		if (list1.length !== list2.length) return false;

		// Sort and compare as strings (works for simple values)
		return JSON.stringify(list1.sort()) === JSON.stringify(list2.sort());
	}

	function addComment(testName: TestKey, hint: string = '') {
		popupController.open<AddCommentPopupProps>(AddCommentPopup, {
			subject: StringUtils.convertCamelCaseToHuman(testName) + hint,
			comment: comments.find((comment) => comment.testName === testName)?.comment,
			onSave: (comment: string) => {
				if (comments.find((comment) => comment.testName === testName)) {
					setComments(
						comments.map((item) => {
							if (item.testName === testName) {
								return { testName, comment };
							}
							return item;
						})
					);
				} else {
					setComments([...comments, { testName, comment }]);
				}
				rsToastify.success('Comment added successfully.', 'Success');
			}
		});
	}
	async function handleDisconnectAndSave() {
		let testResultsClone = ObjectUtils.clone(testResults);
		try {
			if (!testResultsClone.length) return;

			if (comments) {
				testResultsClone = testResultsClone.map((test) => {
					const comment = comments.find((comment) => comment.testName === test.testName);
					if (comment) {
						return { ...test, comment: comment.comment };
					}
					return test;
				});
			}

			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			const part = await ApiRequestV1.getPartByNumbers({
				partNumber: props.hwid.partNumber,
				serialNumber: props.hwid.serialNumber
			});
			if (!part) {
				rsToastify.error('Missing Part Information', 'Could not find part to save data to.');
				popupController.close(LoadingPopup);
				return;
			}

			const res = await ApiRequestV1.postResult({ data: JSON.stringify(testResultsClone) });

			let artifacts;
			let artifactDetailsList: ArtifactInfo[] = [];
			artifactDetailsList = _testArtifacts;
			const socketIoService = serviceFactory.get('SocketioService');
			if (ObjectUtils.isArrayWithData(fileData)) {
				for (let i = 0; i < fileData.length; i++) {
					const url = await ApiRequestV1.getTestResultPresignedUrl({
						pathFileName: `test-results/flight-tests/${connectionGuid}/${fileData[i].fileName}`,
						mimeType: fileData[i].mimeType
					});

					const fileUploadResult = await socketIoService.uploadFlightLogFile({
						filePath: fileData[i].filePath,
						url: url,
						mimeType: fileData[i].mimeType
					});

					// This whole statement is to capture the error case if the file upload fails
					if (socketIoService.isResponseAnError(fileUploadResult) || !fileUploadResult.success) {
						rsToastify.error('Failed to upload file', 'File Upload Error');
						popupController.close(LoadingPopup);
						return;
					}

					artifactDetailsList = [
						...artifactDetailsList,
						{
							fileUrl: url.split(fileData[i].fileName)[0] + fileData[i].fileName,
							fileSize: Number(fileData[i].fileSize)
						}
					];
				}
			}
			if (artifactDetailsList?.length > 0) {
				artifacts = JSON.stringify({
					data: artifactDetailsList
				});
			}

			// get the number of test keys and results that don't contain generateData (which just means a file entry was added)
			const realTestCount = Object.keys(flightTestData).filter((test) => test !== 'generateData').length;
			const realResultCount = testResultsClone.filter((test) => test.testName !== 'generateData').length;

			const associatedAssembliesData = associatedAssembliesFormGroup.toModel<{ [key: string]: string }>();

			const associatedAssemblies = [];

			for (const key in associatedAssembliesData) {
				const partNumber = assemblyService.decodeHardwareId(associatedAssembliesData[key])?.partNumber ?? '';
				const serialNumber =
					assemblyService.decodeHardwareId(associatedAssembliesData[key])?.serialNumber ?? '';
				associatedAssemblies.push({ partNumber, serialNumber });
			}

			const associatedBatteriesData = associatedBatteries
				.map((battery) => {
					const data = assemblyService.decodeHardwareId(battery);
					if (!data) return false;
					return {
						partNumber: data.partNumber,
						serialNumber: data.serialNumber
					};
				})
				.filter((item): item is { partNumber: string; serialNumber: string } => Boolean(item));

			await ApiRequestV1.postTestResult({
				partId: part.id,
				connectionGuid,
				testName: 'Flight Test',
				status: testResultsClone.some((res) => !res.passed) ? 'FAIL' : 'PASS',
				hasPassed: testResultsClone.every((res) => res.passed),
				isComplete: realResultCount === realTestCount,
				resultId: res.id,
				artifacts,
				...(associatedAssemblies.length && { associatedAssembly: associatedAssemblies }),
				...(associatedBatteriesData.length && { associatedBatteries: associatedBatteriesData })
			});

			rsToastify.success('Test results saved successfully.', 'Test Results Saved');
			socketIoService.sendFlightLogCleanupRequest();
			setTestResults([]);
			setComments([]);
			setTestArtifacts([]);
			setFileData(undefined);
			popupController.close(LoadingPopup);
			props.onDone();
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
			popupController.close(LoadingPopup);
		}
	}

	function renderRightHeaderNode() {
		return (
			<>
				<Button
					look={'containedPrimary'}
					onClick={() => {
						isModified ? handleDisconnectAndSave() : props.onDone();
					}}
				>
					{isModified ? 'Disconnect & Save' : 'Disconnect'}
				</Button>
			</>
		);
	}

	function renderCenterNode() {
		if (!isModified) return <></>;
		const completedCount = testResults.filter((test) => test.testName !== 'generateData').length;
		return (
			<Label variant={'subheader2'} weight={'regular'}>
				{`${completedCount}/${Object.keys(flightTestData).length} completed`}
			</Label>
		);
	}

	function renderTests(testName: TestKey) {
		const testResult = testResults.find((test) => test.testName === testName);
		let status: TestStatus | undefined;
		if (testResult) {
			status = testResult.passed ? 'SUCCESS' : 'ERROR';
		}

		if (['loadVideo', 'loadFlightLog'].includes(testName)) {
			return (
				<Box display={'flex'} justifyContent={'space-between'} mb={16} id={testName} key={testName}>
					<Box>{flightTestData[testName]}</Box>
					<Box alignItems={'center'} height={22}>
						<StatusChip status={status ?? 'PENDING'} />
					</Box>
				</Box>
			);
		} else {
			return (
				<Box id={testName} key={testName}>
					<Box display={'flex'} justifyContent={'space-between'} mb={16}>
						<Box display="flex" gap={4}>
							<Button look="none" className="addCommentButton" onClick={() => addComment(testName)}>
								<Icon iconImg="icon-add-comment" />
							</Button>
							<Label variant={'h3'} weight={'semiBold'}>
								{StringUtils.convertCamelCaseToHuman(testName)}
							</Label>
						</Box>
						<StatusChip status={status ?? 'PENDING'} />
					</Box>
					{flightTestData[testName]}
				</Box>
			);
		}
	}

	function scrollToTest(testId: TestKey) {
		const testElement = document.getElementById(testId);
		if (testElement) {
			testElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	}

	function renderTestList() {
		const elements: JSX.Element[] = [];
		Object.keys(flightTestData).forEach((testName) => {
			const testResult = testResults.find((test) => test.testName === testName);
			let status: TestStatus | undefined;
			if (testResult) {
				status = testResult.passed ? 'SUCCESS' : 'ERROR';
			}

			elements.push(
				<FlightTestItem
					key={testName}
					testName={StringUtils.convertCamelCaseToHuman(testName)}
					isSelected={testName === selectedTest}
					onClick={() => {
						setSelectedTest(testName as TestKey);
						scrollToTest(testName as TestKey);
					}}
					status={status}
				/>
			);
		});

		return elements;
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setAssociatedAssembliesFormGroup((prev) => {
			return prev.clone().update(control);
		});
	}

	async function handlePartLookup(control: RsFormControl<IRsFormControl>) {
		if (!control.value) {
			handleUpdateControl(control);
			return;
		}

		const inputIndex = control.key === 'associatedAssembly' ? 0 : 1;
		const assemblyPartNumbers = getProperPartCheck()[inputIndex];

		const assemblyData = assemblyService.decodeHardwareId(control.value as string);

		if (!assemblyData) {
			rsToastify.warning('Invalid Assembly Data', 'Invalid Assembly Data');
			return;
		}

		const { partNumber, serialNumber } = assemblyData;

		if (!assemblyPartNumbers.includes(partNumber)) {
			rsToastify.error('Part number is not a valid assembly type.', 'Invalid Part Number');
			control.value = '';
			handleUpdateControl(control);
			return;
		}

		try {
			await ApiRequestV1.getPartByNumbers({
				partNumber,
				serialNumber
			});
			handleUpdateControl(control);
		} catch (e) {
			console.error(e);
			rsToastify.error('Part not found.', 'Invalid Part Number');
		}
	}

	function renderBatteryInputs() {
		const batteries = associatedBatteries
			.map((battery, index) => {
				if (!battery) return false;
				return (
					<LabelInputText
						key={index}
						labelTitle={`Battery Used For Testing`}
						value={battery}
						inputMode="text"
						readOnly
						icon={[
							{
								iconImg: 'icon-delete',
								onClick: () => {
									const newBatteries = [...associatedBatteries.filter(Boolean)];
									newBatteries.splice(index, 1);
									setAssociatedBatteries(newBatteries);
								},
								position: 'RIGHT',
								cursorPointer: true
							}
						]}
					/>
				);
			})
			.filter(Boolean);

		batteries.push(
			<LabelInputText
				key={batteries.length + 1}
				labelTitle={`Battery Used For Testing ${batteries.length + 1}`}
				inputMode="text"
				onBlur={async ({ target }) => {
					if (!target.value) return;

					const assemblyData = assemblyService.decodeHardwareId(target.value as string);

					if (!assemblyData) {
						rsToastify.warning('Invalid Assembly Data', 'Invalid Assembly Data');
						return;
					}

					const { partNumber, serialNumber } = assemblyData;

					try {
						const test = await ApiRequestV1.getPartByNumbers({
							partNumber,
							serialNumber
						});
						if (!autoCreateableAssemblyPartNumbers.includes(test.partNumber)) {
							rsToastify.error('Part number is not a battery type.', 'Invalid Part Number');
							target.value = '';
							return;
						}
						const newBatteries = [...associatedBatteries.filter(Boolean), target.value];
						setAssociatedBatteries(newBatteries);
					} catch (e) {
						console.error(e);
						rsToastify.error('Part not found.', 'Invalid Part Number');
					}
				}}
			/>
		);

		return batteries;
	}

	function getProperPartCheck() {
		const assemblyType = assemblyService.getLabelFromPartNumber(props.hwid.partNumber);
		if (!assemblyType) return [];
		if (payloadPartNumbers.includes(props.hwid.partNumber)) return [vehiclePartNumbers, fcuPartNumbers];
		if (vehiclePartNumbers.includes(props.hwid.partNumber)) return [payloadPartNumbers, fcuPartNumbers];
		if (fcuPartNumbers.includes(props.hwid.partNumber)) return [vehiclePartNumbers, payloadPartNumbers];
		else return [];
	}

	function getAssociatedAssemblyLabels() {
		const assemblyType = assemblyService.getLabelFromPartNumber(props.hwid.partNumber);
		if (!assemblyType) return 'Associated Assembly';
		if (payloadPartNumbers.includes(props.hwid.partNumber))
			return ['Vehicle Used For Testing', 'FCU Used For Testing'];
		if (vehiclePartNumbers.includes(props.hwid.partNumber))
			return ['Payload Used For Testing', 'FCU Used For Testing'];
		if (fcuPartNumbers.includes(props.hwid.partNumber))
			return ['Vehicle Used For Testing', 'Payload Used For Testing'];
		else return ['Assembly Used For Testing', 'Assembly Used For Testing'];
	}

	function renderComments() {
		return comments.map((comment, index) => {
			return (
				<CommentBox
					key={index}
					subject={StringUtils.convertCamelCaseToHuman(comment.testName)}
					comment={comment.comment}
					onDelete={() => {
						setComments(comments.filter((item) => item !== comment));
					}}
				/>
			);
		});
	}

	return (
		<>
			<PageHeader
				title={flightLabel}
				isModified={isModified}
				centerNode={renderCenterNode()}
				rightNode={renderRightHeaderNode()}
			/>
			<Box className={'rsFlightTestSection'}>
				<Box className={'testList'}>{renderTestList()}</Box>
				<Box className={'main'}>
					<SelectableInputText
						labelTitle={`${assemblyService.getLabelFromPartNumber(props.hwid.partNumber)} Under Test`}
						control={
							new RsFormControl<string>(
								'main',
								`PN1:${props.hwid.partNumber},REV1:${props.hwid.hardwareRevision},SN1:${props.hwid.serialNumber}`
							)
						}
						isSelected={false}
						isFixed
						onClick={() => {}}
						updateControl={() => {}}
						onBlurOrEnter={async (_value, _enterPressed) => {
							return 'VALID';
						}}
					/>
					<LabelInputText
						labelTitle={getAssociatedAssemblyLabels()[0]}
						inputMode="text"
						control={associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_1)}
						updateControl={debounce(handlePartLookup, 500)}
					/>
					<LabelInputText
						labelTitle={getAssociatedAssemblyLabels()[1]}
						inputMode="text"
						control={associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_2)}
						updateControl={debounce(handlePartLookup, 500)}
					/>
					{renderBatteryInputs()}
					<Box className={'dividerLine'} />
					<Label variant={'h1'} weight={'bold'}>
						Pre-Flight Operations
					</Label>
					<Box className={'dividerLine'} />
					{Object.keys(preFlightTestData).map((test) => renderTests(test as TestKey))}
					<Label variant={'h1'} weight={'bold'}>
						In-Flight Operations
					</Label>
					<Box className={'dividerLine'} />
					{Object.keys(inFlightTestData).map((test) => renderTests(test as TestKey))}
					{showPostFlightSection && (
						<>
							<Label variant={'h1'} weight={'bold'}>
								Post-Flight Operations
							</Label>
							<Box className={'dividerLine'} />
							{Object.keys(postFlightTestData).map((test) => renderTests(test as TestKey))}
						</>
					)}
					{!!comments.length && (
						<>
							<Box className={'dividerLine'} />
							<Label variant={'h1'} weight={'bold'}>
								Comments
							</Label>
							{renderComments()}
						</>
					)}
				</Box>
			</Box>
		</>
	);
};
export default FlightTestSection;
