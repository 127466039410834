import * as React from 'react';
import { Box, popupController } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo } from 'react';
import { ITestCriteria } from '../../../../utils/testCriteria';
import EvaluationItem, {
	EvaluationOptions,
	EvaluationGroup
} from '../../../../components/evaluationItem/EvaluationItem';
import AddCommentPopup, { AddCommentPopupProps } from '../../../../popups/addCommentPopup/AddCommentPopup';
import { StringUtils } from '../../../../utils/utils';

interface PayloadHighSpeedEngagementProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	PROD_ENGAGEMENT = 'confirmProductionEngagementNotification',
	ENGAGEMENT_30_1 = 'performHighSpeedEngage30Deg1Ir',
	ENGAGEMENT_30_2 = 'performHighSpeedEngage30Deg2Ir',
	ENGAGEMENT_30_3 = 'performHighSpeedEngage30Deg3Eo'
}

type HighSpeedEngagementsGroup = EvaluationGroup & {
	confirmProductionEngagementNotification: EvaluationOptions | undefined;
	performHighSpeedEngage30Deg1Ir: EvaluationOptions | undefined;
	performHighSpeedEngage30Deg2Ir: EvaluationOptions | undefined;
	performHighSpeedEngage30Deg3Eo: EvaluationOptions | undefined;
};

type EvaluationKey = keyof HighSpeedEngagementsGroup;

const PayloadHighSpeedEngagement: React.FC<PayloadHighSpeedEngagementProps> = (props) => {
	const TEST_NAME: TestKey = 'highSpeedEngagements';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const [comments, setComments] = useRecoilState<{ testName: string; comment: string }[]>(globalState.testComments);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	const [evaluations, setEvaluations] = useState<HighSpeedEngagementsGroup>({
		confirmProductionEngagementNotification: res ? res.data['confirmProductionEngagementNotification'] : undefined,
		performHighSpeedEngage30Deg1Ir: res ? res.data['performHighSpeedEngage30Deg1Ir'] : undefined,
		performHighSpeedEngage30Deg2Ir: res ? res.data['performHighSpeedEngage30Deg2Ir'] : undefined,
		performHighSpeedEngage30Deg3Eo: res ? res.data['performHighSpeedEngage30Deg3Eo'] : undefined
	});

	function handleEvaluationClick(evaluation: EvaluationOptions | undefined, key: EvaluationKey) {
		setEvaluations((prevState) => {
			const updatedState = { ...prevState };
			updatedState[key] = evaluation;

			const result = testFixtureService.validateEvaluationTest(
				TEST_NAME,
				updatedState,
				undefined,
				props.testCriteria
			);
			if (result !== undefined) {
				if (!result) {
					popupController.open<AddCommentPopupProps>(AddCommentPopup, {
						subject: StringUtils.convertCamelCaseToHuman(TEST_NAME),
						hint: 'Describe Test Failure',
						comment: comments.find((comment) => comment.testName === TEST_NAME)?.comment,
						onSave: (comment: string) => {
							if (comments.find((comment) => comment.testName === TEST_NAME)) {
								setComments(
									comments.map((item) => {
										if (item.testName === TEST_NAME) {
											return { testName: TEST_NAME, comment };
										}
										return item;
									})
								);
							} else {
								setComments([...comments, { testName: TEST_NAME, comment }]);
							}
						}
					});
				}
			}
			return updatedState;
		});
	}

	return (
		<Box className={'rsHighSpeedEngagement'}>
			<EvaluationItem
				label={'Verify R1GCS notification stating "Production Engagement Enabled"'}
				resettable={true}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, FormKeys.PROD_ENGAGEMENT);
				}}
				evaluation={evaluations[FormKeys.PROD_ENGAGEMENT]}
			/>
			<EvaluationItem
				label={'Performed 30\u00B0 High Speed Engagement #1 using IR video feed'}
				resettable={true}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, FormKeys.ENGAGEMENT_30_1);
				}}
				evaluation={evaluations[FormKeys.ENGAGEMENT_30_1]}
			/>
			<EvaluationItem
				label={'Performed 30\u00B0 High Speed Engagement #2 using IR video feed'}
				resettable={true}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, FormKeys.ENGAGEMENT_30_2);
				}}
				evaluation={evaluations[FormKeys.ENGAGEMENT_30_2]}
			/>
			<EvaluationItem
				label={'Performed 30\u00B0 High Speed Engagement #3 using EO video feed'}
				resettable={true}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, FormKeys.ENGAGEMENT_30_3);
				}}
				evaluation={evaluations[FormKeys.ENGAGEMENT_30_3]}
			/>
		</Box>
	);
};
export default PayloadHighSpeedEngagement;
