import * as React from 'react';
import { useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import {
	Box,
	Button,
	Label,
	Popup,
	popupController,
	PopupProps,
	LabelInputText,
	RsFormGroup,
	RsFormControl,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import colors from '../../themes/colors.scss?export';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

enum FormKeys {
	PASSWORD = 'password'
}

export type ResetIntegrationPopupProps = PopupProps & {
	onConfirm: () => void;
};

const ResetIntegrationPopup: React.FC<ResetIntegrationPopupProps> = (props) => {
	const userService = serviceFactory.get('UserService');
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.PASSWORD, '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')])
		])
	);

	async function handleSubmit() {
		const userEmail = user?.email;
		const formValue = formGroup.toModel<{ password: string }>();
		if (userEmail) {
			const validatedUser = await userService.validateUserSession(userEmail, formValue.password);
			if (validatedUser) {
				props.onConfirm();
				popupController.close(ResetIntegrationPopup);
			} else {
				setPasswordError(true);
				return;
			}
		}
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsResetIntegrationPopup'}>
				<PopupHeader
					title={'Reset Integration Bit'}
					onClose={() => {
						popupController.close(ResetIntegrationPopup);
					}}
				/>
				<Box className={'resetIntegrationContainer'} display={'grid'} gap={16} mt={24}>
					<Label variant={'body1'} weight={'regular'}>
						Enter your password to reset the integration bit.
					</Label>
					<Box className={'passwordContainer'}>
						<LabelInputText
							inputMode={'text'}
							type={'password'}
							labelTitle={'Password'}
							control={formGroup.get(FormKeys.PASSWORD)}
							updateControl={handleUpdateControl}
						/>
						{passwordError && (
							<Label
								variant={'caption1'}
								weight={'regular'}
								className={'passwordError'}
								color={colors.accentError}
							>
								Invalid Password
							</Label>
						)}
					</Box>
					<Box className={'buttonContainer'} display={'flex'} justifyContent={'space-between'}>
						<Button
							look={'outlinedPrimary'}
							onClick={() => {
								popupController.close(ResetIntegrationPopup);
							}}
						>
							Cancel
						</Button>
						<Button look={'containedPrimary'} onClick={handleSubmit}>
							Reset Bit
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};

export default ResetIntegrationPopup;
