import * as React from 'react';
import './StatusChip.scss';
import { Box, Icon, Label, LabelProps } from '@redskytech/framework/ui';
import { StatusIconMap, TestStatus } from '../../pages/testDetailsPage/TestDetailsPage';
import classNames from 'classnames';

interface StatusChipProps {
	status: TestStatus;
	labelVariant?: LabelProps['variant'];
	isSmall?: boolean;
}

const StatusTitleMap: { [key in TestStatus]: string } = {
	SUCCESS: 'Pass',
	ERROR: 'Fail',
	COMPLETE: 'Complete',
	INCOMPLETE: 'Incomplete',
	PENDING: 'Incomplete',
	WARNING: 'Incomplete',
	INFO: 'Pass',
	AUDITPASS: 'Pass',
	SAFE: 'Safe',
	UNKNOWN: 'Unknown',
	ARMED: 'Armed'
};

const StatusChip: React.FC<StatusChipProps> = (props) => {
	return (
		<Box className={classNames('rsStatusChip', props.status.toLowerCase(), { small: props.isSmall })}>
			<Icon {...StatusIconMap[props.status === 'WARNING' ? 'PENDING' : props.status]} fontSize={12} />
			<Label variant={props.labelVariant || 'body2'} weight={'regular'}>
				{StatusTitleMap[props.status]}
			</Label>
		</Box>
	);
};
export default StatusChip;
