import * as React from 'react';
import './TestAuditCheck.scss';
import { Box, Icon, popupController, rsToastify } from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { useEffect } from 'react';

import useIsMounted from '../../customHooks/useIsMounted';
import { TestPrompt } from '../../services/testFixture/ITestFixtureService';
import { StatusIconMap, TestStatus } from '../../pages/testDetailsPage/TestDetailsPage';
import AuditBanner, { AuditErrorSection } from '../auditBanner/AuditBanner';
import AuditPopup, { AuditPopupProps } from '../../popups/auditPopup/AuditPopup';

import { StringUtils } from '../../utils/utils';
import {
	AssemblyTreeTestAuditResult,
	AssemblyTestAuditResult,
	processPartFamilyTreeAudit
} from '../../utils/generateAuditList';

export interface TestAuditCheckProps {
	partId: number;
	testAuditData?: AssemblyTreeTestAuditResult;
	serialNumber?: string;
	mode: 'NOTICE' | 'POPUP' | 'BOTH' | 'ICON' | 'ICON_SUMMARY';
	testStats?: (value: number, errorList: string[]) => void;
	excludeFlights?: boolean;
}

const TestAuditCheck: React.FC<TestAuditCheckProps> = (props) => {
	const isMounted = useIsMounted();
	const [isHovered, setIsHovered] = React.useState<boolean>(false);
	const [clearPopup, setClearPopup] = React.useState<boolean>(false);
	const [popupOpenSelected, setPopupOpenSelected] = React.useState<boolean>(false);
	const [iconLevel, setIconLevel] = React.useState<string>('PENDING');
	const [testAuditData, setTestAuditData] = React.useState<AssemblyTreeTestAuditResult | undefined>(undefined);

	function convertLevelToStatus(level: Omit<TestPrompt['level'], 'default'>): TestStatus {
		switch (level) {
			case 'FAIL':
				return 'ERROR';
			case 'PASS':
				return 'AUDITPASS';
			case 'INCOMPLETE':
				return 'WARNING';
			case 'PENDING':
			default:
				return 'PENDING';
		}
	}

	useEffect(() => {
		if (!props.testStats) return;
		if (!testAuditData) return;
		let incompleteTestList: string[] = [];
		testAuditData.testInfo.forEach((testInfo) => {
			incompleteTestList = [...incompleteTestList, ...testInfo.failedTests];
			incompleteTestList = [...incompleteTestList, ...testInfo.missingTests];
			incompleteTestList = [...incompleteTestList, ...testInfo.incompleteTests];
		});

		props.testStats(testAuditData.testIssueCount, incompleteTestList);
	}, [testAuditData]);

	useEffect(() => {
		if (props.partId === 0) return;

		(async () => {
			try {
				const res = await ApiRequestV1.getPart({ partId: props.partId });
				if (!isMounted) return;
				if (!res) {
					rsToastify.error('Error getting part information', 'Error');
					return;
				}
				if (props.testAuditData) {
					setTestAuditData(props.testAuditData);
				} else {
					const partAudit = await processPartFamilyTreeAudit(
						props.partId,
						props.excludeFlights === undefined ? false : props.excludeFlights
					);
					if (!partAudit) {
						rsToastify.error('Error getting part audit information', 'Error');
						return;
					}
					setTestAuditData(partAudit);
				}
			} catch (err) {
				rsToastify.error('Error performing checks', 'Error');
				console.error(err);
			}
		})();
	}, []);

	useEffect(() => {
		if (!testAuditData) return;
		if (testAuditData.testIssueCount === 0) setIconLevel('PASS');
		else if (testAuditData.testFailedCount > 0) setIconLevel('FAIL');
		else setIconLevel('INCOMPLETE');
	}, [testAuditData]);

	useEffect(() => {
		if (clearPopup || !testAuditData || testAuditData.allTestsComplete) return;

		if (props.mode === 'NOTICE') return;
		if (props.mode === 'ICON') return;
		if (props.mode === 'ICON_SUMMARY') return;
		const popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Tests Deficient',
			auditErrors: createMessageFromAuditList(testAuditData.testInfo),
			message: `Do you want to proceed?`,
			confirmButtonText: 'Continue',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setClearPopup(true);
			},
			onCancel: () => {
				setClearPopup(true);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [clearPopup, testAuditData, props.mode]);

	useEffect(() => {
		if (!popupOpenSelected) return;
		if (!testAuditData) return;
		if (props.mode === 'NOTICE') return;
		if (props.mode === 'POPUP') return;
		const popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Tests Deficient',
			auditErrors: createMessageFromAuditList(testAuditData.testInfo),
			message: `Click View Details to go to the assembly page.`,
			confirmButtonText: 'View Details',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setPopupOpenSelected(false);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			},
			onCancel: () => {
				setPopupOpenSelected(false);
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [popupOpenSelected, testAuditData, props.mode]);

	function createMessageFromAuditList(auditList: AssemblyTestAuditResult[] | undefined) {
		if (!auditList) return [];
		const outputObjects: AuditErrorSection[] = [];
		for (const audit of auditList) {
			const newoutputObject: AuditErrorSection = { group: audit.assemblyName, errors: [] };
			if (audit.failedTests.length > 0) {
				audit.failedTests.forEach((test) => {
					newoutputObject.errors.push(StringUtils.convertCamelCaseToHuman(test));
				});
			}
			if (audit.missingTests.length > 0) {
				audit.missingTests.forEach((test) => {
					newoutputObject.errors.push(StringUtils.convertCamelCaseToHuman(test));
				});
			}
			if (audit.incompleteTests.length > 0) {
				audit.incompleteTests.forEach((test) => {
					newoutputObject.errors.push(StringUtils.convertCamelCaseToHuman(test));
				});
			}
			if (newoutputObject.errors.length > 0) outputObjects.push(newoutputObject);
		}

		return outputObjects;
	}

	if (props.mode === 'NOTICE' || props.mode === 'BOTH') {
		return (
			<Box className={'rsTestAuditCheck'}>
				{testAuditData && !testAuditData?.allTestsComplete && (
					<AuditBanner
						level={'warn'}
						buttons={[]}
						disableAudio={true}
						disabled={false}
						id={'auditCheck'}
						position={0}
						text={`Deficient Tests:`}
						auditErrors={createMessageFromAuditList(testAuditData?.testInfo)}
					/>
				)}
			</Box>
		);
	} else if (props.mode === 'POPUP' && testAuditData && !testAuditData?.allTestsComplete && !clearPopup) {
		return <Box className={'rsTestAuditCheck'} />;
	} else if (props.mode === 'ICON') {
		return (
			<Box className={'rsTestAuditCheck'} flexDirection={'row'} gap={4}>
				<Icon {...StatusIconMap[convertLevelToStatus(iconLevel)]} />{' '}
			</Box>
		);
	} else if (props.mode === 'ICON_SUMMARY') {
		return (
			<Box
				className={'rsTestAuditCheck'}
				flexDirection={'row'}
				gap={4}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => {
					setIsHovered(false);
				}}
				onClick={() => {
					if (testAuditData && testAuditData.testIssueCount > 0) {
						setPopupOpenSelected(true);
					}
				}}
			>
				<Icon
					cursorPointer={testAuditData && testAuditData.testIssueCount > 0}
					{...StatusIconMap[convertLevelToStatus(iconLevel)]}
				/>
				{testAuditData && testAuditData.testIssueCount > 0 ? testAuditData.testIssueCount : ''}
				{isHovered && testAuditData && testAuditData.testIssueCount > 0 && (
					<Box className={'toolTip'}>
						<Box className={'container'}>
							<AuditBanner
								level={'warn'}
								buttons={[]}
								disableAudio={true}
								disabled={false}
								id={'auditCheck'}
								position={0}
								text={`Deficient Tests:`}
								auditErrors={createMessageFromAuditList(testAuditData?.testInfo)}
							/>
						</Box>
					</Box>
				)}
			</Box>
		);
	} else return <Box className={'rsTestAuditCheck'} />;
};

export default TestAuditCheck;
