import { Box, Label } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import KitCheckoutRow from '../../../components/kitCheckoutRow/KitCheckoutRow';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import { InspectionGroupType } from '../../../services/kit/IKitService';
import { inspectionGroups } from '../../../services/kit/kit.data';
import colors from '../../../themes/colors.scss?export';
import './IntersiteChecklistGroup.scss';

export interface IntersiteChecklistGroupProps {
	group: InspectionGroupType;
	idList: HardwareIdDecoded[];
	site: string;
	removeItem?: (group: InspectionGroupType, HWID: HardwareIdDecoded) => void;
	allExpanded?: boolean;
	triggerExpanded?: boolean;
	locked: boolean;
	excludeFlights?: boolean;
}
const IntersiteChecklistGroup: React.FC<IntersiteChecklistGroupProps> = (props) => {
	const [allExpanded, setAllExpanded] = useState<boolean>(false);
	const [triggerExpansion, setTriggerExpansion] = useState<boolean>(false);
	const groupRows = useMemo(() => {
		const rows = [];
		for (const item of props.idList.sort((a, b) => {
			return a.serialNumber.localeCompare(b.serialNumber);
		})) {
			rows.push(
				<KitCheckoutRow
					key={`${item.serialNumber.replace(' ', '_')}_${item.partNumber}`}
					removeItem={removeRow}
					HWID={item}
					checklistItems={inspectionGroups[props.group].checklist[props.site]}
					globalExpanded={allExpanded}
					expansionTrigger={triggerExpansion}
					excludeFlights={props.excludeFlights === undefined ? false : props.excludeFlights}
					locked={props.locked !== undefined ? props.locked : false}
				/>
			);
			if (!item.children || !item.children.length) continue;

			for (const child of item.children) {
				rows.push(
					<KitCheckoutRow
						key={`${child.serialNumber.replace(' ', '_')}_${child.partNumber}`}
						removeItem={removeRow}
						HWID={child}
						checklistItems={inspectionGroups[props.group].checklist[props.site]}
						globalExpanded={allExpanded}
						expansionTrigger={triggerExpansion}
						excludeFlights={props.excludeFlights === undefined ? false : props.excludeFlights}
						locked={props.locked !== undefined ? props.locked : false}
					/>
				);
			}
		}

		return rows;
	}, [props.idList, triggerExpansion, props.locked]);

	useEffect(() => {
		if (props.allExpanded == undefined) return;
		if (props.triggerExpanded == undefined) return;
		// This will set to the expanded value,
		setAllExpanded(props.allExpanded);
		setTriggerExpansion((prev) => {
			return !prev;
		});
	}, [props.triggerExpanded]);

	function removeRow(HWID: HardwareIdDecoded) {
		if (props.removeItem) {
			props.removeItem(props.group, HWID);
		}
	}

	return (
		<Box className={'rsIntersiteChecklistGroup'}>
			<Label variant={'body1'} weight={'bold'}>
				{inspectionGroups[props.group].label}
			</Label>
			<Box display={'flex'} gap={16} className={'intersiteGroupListHeader'} minHeight={16}>
				<Box className={'collapseColumn'}></Box>
				<Box className={'serialColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Serial Number
					</Label>
				</Box>
				<Box className={'assembliesColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Assemblies
					</Label>
				</Box>
				<Box className={'testsColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Tests
					</Label>
				</Box>
				<Box className={'inspectionCheckColumn'}>
					<Label color={colors.neutralGrey700} variant={'caption1'} weight={'regular'}>
						Inspection
					</Label>
				</Box>
				<Box className={'deleteColumn'}></Box>
			</Box>
			{groupRows}
			<Box className={'bottom'} height={32} />
		</Box>
	);
};
export default IntersiteChecklistGroup;
